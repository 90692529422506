import { NumberProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class PaginationMixin extends Vue {
  @NumberProp(30, 1)
  protected readonly pageSize!: number;

  protected currentPage = 1;

  protected get count(): number {
    return 0;
  }

  protected get more(): boolean | undefined {
    return undefined;
  }

  protected get totalPages(): number {
    return this.more === undefined ? Math.ceil(this.count / this.pageSize) : this.currentPage + Number(this.more);
  }

  protected get skip(): number {
    return this.pageSize * (this.currentPage - 1);
  }

  protected get take(): number {
    return this.pageSize;
  }
}
