




























import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppManagerActivityLogEntryViewQuery,
  AppManagerActivityLogEntryViewQueryVariables,
} from './__generated__/AppManagerActivityLogEntryViewQuery';

type LogEntry = AppManagerActivityLogEntryViewQuery['treeNodes']['first']['activityLogEntries']['first'];

@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ActivityLogEntryView): AppManagerActivityLogEntryViewQueryVariables {
        const { treeNodeId, activityLogEntryId } = this;
        return { treeNodeId, activityLogEntryId };
      },
    },
  },
  data() {
    return {
      treeNodes: undefined,
    };
  },
})
export default class ActivityLogEntryView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @StringProp(true)
  private readonly activityLogEntryId!: string;

  private readonly treeNodes?: AppManagerActivityLogEntryViewQuery['treeNodes'];

  private get logEntry(): LogEntry | undefined {
    return this.treeNodes?.first.activityLogEntries.first;
  }

  private get date(): Date | undefined {
    return this.logEntry?.checkInDate ?? this.logEntry?.earliestCheckInDate ?? undefined;
  }
}
