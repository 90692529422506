












import { Component, Vue } from 'vue-property-decorator';
import { BooleanProp } from '@/util/prop-decorators';
import UiClickable from '@/components/clickables/Clickable.global.vue';
import Spinner from '@/components/clickables/Spinner.vue';

@Component({ components: { UiClickable, Spinner }, inheritAttrs: false })
export default class WizardButton extends Vue {
  @BooleanProp()
  private readonly busy!: boolean;

  @BooleanProp()
  private readonly secondary!: boolean;

  // Computed property to determine button classes
  get buttonClasses(): any {
    return {
      'wizard-button-secondary': this.secondary,
      'wizard-button-disabled': this.busy || this.$attrs.disabled,
    };
  }
}
