





















import { floorLabel } from '@/features/domain-ui/property-floor/label';
import { ObjectProp } from '@/util/prop-decorators';
import { PropertyFloorType } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import { Floor } from './model';

@Component
export default class BuildingFloor extends Vue {
  @ObjectProp(true)
  private readonly floor!: Floor;

  private get label(): string {
    return floorLabel(this.floor);
  }

  private get roof(): boolean {
    return this.floor.type === PropertyFloorType.ROOF;
  }
}
