












import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppManagerTreeNodeAlertsViewQuery,
  AppManagerTreeNodeAlertsViewQueryVariables,
} from './__generated__/AppManagerTreeNodeAlertsViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: AlertsView): AppManagerTreeNodeAlertsViewQueryVariables {
        return { treeNodeId: this.treeNodeId, take: this.take, skip: this.skip, activeOnly: this.activeOnly };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class AlertsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeAlertsViewQuery['treeNodes'];

  private activeOnly = true;

  protected get more(): boolean {
    return this.treeNodes?.first.alerts.more ?? false;
  }
}
