var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.treeNodes)?_c('ui-panel',[(_vm.groundPlanFileId !== undefined)?_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-left":""}},[_c('h2',{staticClass:"ground-plan-label"},[_vm._v(_vm._s(_vm.groundPlanLabel))])]),_c('ui-cell',[(_vm.editable)?_c('ui-wizard-button',{attrs:{"secondary":""},on:{"click":function($event){return _vm.setGroundPlanFile(null)}}},[_vm._v("Grundriss entfernen / ändern")]):_vm._e()],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-input-switch',{attrs:{"align-right":""},model:{value:(_vm.editable),callback:function ($$v) {_vm.editable=$$v},expression:"editable"}},[_vm._v("Bearbeiten")])],1)],1)],1),(_vm.editable)?_c('ui-cell',[_c('ui-form-message',[_vm._v("Platzieren Sie Geräte auf dem Grundriss in dem Sie sie an die gewünschte Stelle ziehen. Um eine Platzierung zu entfernen ziehen Sie das Gerätesymbol an eine Stelle außerhalb des markierten Bereichs.")])],1):_vm._e(),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-flex',{staticClass:"ground-plan"},[_c('ui-cell',{staticClass:"left",attrs:{"grow":10000,"min":300}},[_c('ui-image-canvas',{ref:"imageCanvas",attrs:{"src":_vm.imageSrc,"placements":_vm.placements,"draggable":_vm.editable},on:{"placement-dropped":function($event){return _vm.placeGroundPlanSpot($event.value.spot.id, $event.position || null)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var draggable = ref.draggable;
var dragging = ref.dragging;
return [_c('ground-plan-spot-placement',{attrs:{"spot":value.spot,"disabled":draggable,"dragging":dragging},on:{"click":_vm.routeToSpotPage}})]}}],null,false,1635809034)})],1),(_vm.unpositionedPlacements.length > 0)?_c('ui-cell',{staticClass:"right",attrs:{"min":260}},[_c('div',{staticClass:"scroll"},[_c('ui-table',{attrs:{"columns":[{ name: 'spot', label: 'Nicht platzierte Geräte' }],"rows":_vm.unpositionedPlacements,"fixed":""},scopedSlots:_vm._u([{key:"spot",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"unpositioned",attrs:{"draggable":_vm.editable ? 'true' : undefined},on:{"dragstart":function($event){return _vm.$refs.imageCanvas.dragStart($event, row.id)}}},[_c('domain-ui-spot-info',{attrs:{"spot":row.spot,"disabled":_vm.editable},on:{"click":_vm.routeToSpotPage}})],1)]}}],null,false,1496725558)})],1)]):_vm._e()],1)],1)],1):(_vm.treeNodes.first.files.items.length > 0)?_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-input-select',{attrs:{"options":_vm.treeNodes.first.files.items,"value":_vm.groundPlanFileId,"option-label":function (ref) {
	var filename = ref.filename;
	var label = ref.label;

	return ("" + (label ? label : filename));
},"placeholder":"Bitte wählen Sie die Bilddatei aus, die Sie als Grundriss verwenden möchten."},on:{"update":function($event){return _vm.setGroundPlanFile($event.id)}}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Neue Bilder können Sie im "),_c('ui-link',{on:{"click":function($event){return _vm.$router.push({ name: 'AppManager/TreeNode/Files' })}}},[_vm._v("Tab "),_c('em',[_vm._v("Dokumente")])]),_vm._v(" hochladen.")],1)],1)],1):_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Es wurden noch keine Dateien hinterlegt, die als Grundriss verwendet werden können. Bitte laden Sie den Grundriss im "),_c('ui-link',{on:{"click":function($event){return _vm.$router.push({ name: 'AppManager/TreeNode/Files' })}}},[_vm._v("Tab "),_c('em',[_vm._v("Dokumente")])]),_vm._v(" hoch. Folgende Dateiformate können als Grundriss verwendet werden: JGP, PNG & SVG.")],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }