




import { RequiredProp } from '@/util/prop-decorators';
import { Component as ComponentInterface } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DefaultView extends Vue {
  @RequiredProp()
  private readonly defaultView!: ComponentInterface | string;
}
