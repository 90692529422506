


























import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { formatMetricName, formatMetricValue } from '@/features/core/util/metric-formatters';
import { normalizeMetricPoint } from '@/features/core/util/metrics';
import { BooleanProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { AppManagerTreeNodeGroundPlanViewSpotFragment } from './__generated__/AppManagerTreeNodeGroundPlanViewSpotFragment';

type FormattedMetrics = {
  name: string;
  value: string | null;
};

@Component
export default class GroundPlanSpotPlacement extends Mixins(DeviceRoleMapMixin) {
  @ObjectProp()
  private readonly spot!: AppManagerTreeNodeGroundPlanViewSpotFragment;

  @BooleanProp()
  private readonly disabled!: boolean;

  @BooleanProp()
  private readonly dragging!: boolean;

  private get icon(): string | undefined {
    const { sm, md } = this.deviceRoleMap[this.spot.role]?.icons ?? {};

    return sm ?? md;
  }

  private get formattedMetrics(): FormattedMetrics[] {
    return this.spot.metrics
      .map(({ name, latest }) => {
        const latestMetricPoint = normalizeMetricPoint(latest);

        if (!latestMetricPoint) {
          return {
            name,
            value: null,
          };
        }
        return {
          name,
          value: formatMetricValue(name, latestMetricPoint.value),
        };
      })
      .filter(({ value }) => value !== null);
  }

  private formatMetricName = formatMetricName;
}
