








import { ApartmentRow } from '@/features/domain-ui/apartment-table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppManagerTreeNodeApartmentsViewQuery,
  AppManagerTreeNodeApartmentsViewQueryVariables,
} from './__generated__/AppManagerTreeNodeApartmentsViewQuery';
import query from './view.gql';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';

@Component({
  apollo: {
    treeNodes: {
      query,
      pollInterval: 60000,
      variables(this: ApartmentsView): AppManagerTreeNodeApartmentsViewQueryVariables {
        return { treeNodeId: this.treeNodeId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class ApartmentsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeApartmentsViewQuery['treeNodes'];

  private get apartments(): ApartmentRow[] {
    if (this.treeNodes === undefined) {
      return [];
    }

    return [
      ...('propertySubdivisions' in this.treeNodes.first ? this.treeNodes.first.propertySubdivisions.items : []),
      ...('sharedPropertySubdivisions' in this.treeNodes.first
        ? this.treeNodes.first.sharedPropertySubdivisions.items
        : []),
    ];
  }

  protected get count(): number {
    if (this.treeNodes === undefined) {
      return 0;
    }

    if ('sharedPropertySubdivisions' in this.treeNodes.first) {
      return this.treeNodes.first.sharedPropertySubdivisions.count;
    }

    if ('propertySubdivisions' in this.treeNodes.first) {
      return this.treeNodes.first.propertySubdivisions.count;
    }

    return 0;
  }
}
