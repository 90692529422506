



















import { defineComponent } from '@vue/composition-api';
import {
  CoreNotificationsMixinQuery,
  CoreNotificationsMixinQueryVariables,
} from '@/features/core/components/mixins/notifications/__generated__/CoreNotificationsMixinQuery';
import query from '@/features/core/components/mixins/notifications/notifications.gql';
import markNotificationsAsReadMutation from '@/features/core/components/mixins/notifications/mark-notifications-as-read.gql';
import assignNotificationMutation from '@/features/core/components/mixins/notifications/assign-notification.gql';
import resolveNotificationMutation from '@/features/core/components/mixins/notifications/resolve-notification.gql';
import { mapActions } from 'vuex';

type Notification = CoreNotificationsMixinQuery['notifications']['items'][number];

interface ContextNotificationsProps {
  skip: CoreNotificationsMixinQueryVariables['skip'];
}

export default defineComponent<ContextNotificationsProps, Record<string, unknown>, any, any>({
  components: {},
  props: {
    skip: {
      required: false,
      default: 0,
      type: Number,
    },
    take: {
      required: false,
      default: 10,
      type: Number,
    },
    read: {
      required: false,
      default: false,
      type: Boolean,
    },
    contextIds: {
      required: false,
      default: null,
      type: Array,
    },
    types: {
      required: false,
      default: null,
      type: Array,
    },
  },
  data() {
    return {
      loading: true,
      notifications: [] as CoreNotificationsMixinQuery['notifications']['items'],
      currentPage: 1,
      totalPages: 10,
    };
  },
  computed: {
    empty(): boolean {
      return !this.$apollo.queries.notifications.loading && this.notifications?.items.length === 0;
    },
  },
  async created() {
    await this.loadNotifications();
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async loadNotifications() {
      const { data } = await this.$apollo.query({
        query: query,
        fetchPolicy: 'network-only',
        variables: {
          take: this.take,
          skip: this.skip,
          read: this.read,
          contextIds: this.contextIds,
          types: this.types,
        },
      });
      if (data?.notifications?.items) {
        this.notifications = data.notifications.items;
        this.totalPages = Math.max(1, Math.ceil((data?.count ?? 0) / this.take));
      }
      this.loading = false;
    },
    async changeReadAction(notification: Notification, read: boolean): Promise<void> {
      await this.$apollo.mutate({
        mutation: markNotificationsAsReadMutation,
        variables: { input: { notificationIds: [notification.id], types: this.types, unread: !read } },
      });
    },
    async changeAssigneeAction(notification: Notification, newAssignee: Notification['assignee']): Promise<void> {
      await this.$apollo.mutate({
        mutation: assignNotificationMutation,
        variables: { input: { notificationId: notification.id, assigneeId: newAssignee?.id } },
      });
    },
    async editResolutionAction(
      notification: Notification,
      resolved: Notification['resolved'],
      notes: Notification['notes'],
    ): Promise<void> {
      await this.$apollo.mutate({
        mutation: resolveNotificationMutation,
        variables: { input: { notificationId: notification.id, reset: !resolved, notes } },
      });
    },
    async markAllAsRead(): Promise<void> {
      await this.$apollo.mutate({
        mutation: markNotificationsAsReadMutation,
        variables: {
          input: {
            contextIds: this.contextIds,
            notificationIds: this.notifications?.items.map(({ id }: any) => id),
            types: this.types,
            until: this.notifications[this.notifications.length - 1]?.creationDate,
          },
        },
      });
    },
  },
});
