






import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import { AppManagerAppPropertiesViewQuery } from './__generated__/AppManagerAppPropertiesViewQuery';

@Component({
  apollo: {
    properties: {
      query,
      pollInterval: 60000,
    },
  },
  data() {
    return { properties: undefined };
  },
})
export default class PropertiesView extends Mixins(TreeNodeLocationGeneratorMixin) {
  private readonly properties?: AppManagerAppPropertiesViewQuery['properties'];
}
