












import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppManagerAppAlertsViewQuery,
  AppManagerAppAlertsViewQueryVariables,
} from './__generated__/AppManagerAppAlertsViewQuery';

@Component({
  apollo: {
    alerts: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: AlertsView): AppManagerAppAlertsViewQueryVariables {
        return { activeOnly: this.activeOnly, take: this.take, skip: this.skip };
      },
    },
  },
  data() {
    return { alerts: undefined };
  },
})
export default class AlertsView extends Mixins(PaginationQueryStringMixin) {
  private readonly alerts?: AppManagerAppAlertsViewQuery['alerts'];

  private activeOnly = true;

  protected get more(): boolean {
    return this.alerts?.more ?? false;
  }
}
