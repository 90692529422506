var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":"Eintragungslink erzeugen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Eintragungslink erzeugen")])]}},{key:"content",fn:function(){return [_c('ui-form',{attrs:{"initial-data":_vm.bookmark,"action":_vm.bookmarkActivityTreeNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',{staticStyle:{"max-width":"480px"}},[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox-list","label":"Aktivitäten auswählen","path":"activityIds","initial-path":"activities","all":"","initial-transformer":_vm.pluckIds,"options":_vm.activityOptions}})],1),(_vm.bookmark)?_c('ui-cell',[_c('ui-flex',{attrs:{"items":"center","nowrap":""}},[_c('ui-cell',{attrs:{"grow":0,"shrink":1,"basis":"auto","text-overflow":""}},[_c('ui-link',{attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.url))])],1),_c('ui-cell',{attrs:{"grow":0,"basis":"auto"}},[_c('ui-clickable',{on:{"click":_vm.copyToClipboard}},[_c('img',{staticClass:"clickboard-icon",attrs:{"src":require("@/features/app-manager/assets/copy-to-clipboard.svg")}})])],1)],1)],1):_vm._e(),(_vm.dataUrl)?_c('ui-cell',{attrs:{"basis":1}},[_c('img',{staticClass:"qr-img",attrs:{"src":_vm.dataUrl}})]):_vm._e(),(_vm.bookmark)?_c('ui-cell',{attrs:{"grow":0}},[_c('ui-wizard-button',{attrs:{"secondary":""},on:{"click":_vm.downloadQrCode}},[_vm._v("QR-Code herunterladen")])],1):_vm._e(),_c('ui-cell',{attrs:{"grow":0,"basis":"auto","push-right":""}},[_c('ui-flex',[(_vm.bookmark)?_c('ui-cell',[_c('ui-wizard-button',{attrs:{"secondary":""},on:{"click":_vm.removeBookmark}},[_vm._v("Deaktivieren")])],1):_vm._e(),_c('ui-cell',[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !formData.activityIds},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)],1)],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }