
























import { defineComponent } from '@vue/composition-api';
import { Column } from '@/features/ui/table/model';
import { Order } from '@/types/iot-portal';
import { GatewaySpots_gateways_first_deviceMounts_items } from '@/features/core/components/connected-devices/__generated__/GatewaySpots';
import queryGet from '@/features/core/components/connected-devices/connected-device-parent.gql';

interface ConnectedDevicesProps {
  gatewayId: string;
}
export default defineComponent<ConnectedDevicesProps, Record<string, unknown>, any, any>({
  components: {},
  props: {
    treeNodeId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      columns: [
        { name: 'path', label: 'Location', width: 150 },
        { name: 'name', label: 'Name', width: 150 },
      ] as Column[],
      sortKeys: [
        {
          key: 'name',
          direction: Order.ASC,
        },
      ],
      loading: true,
      connectedDevices: [],
      // pagination
      currentPage: 1,
      totalPages: null,
      pageSize: 10,
    };
  },
  computed: {},
  async created() {
    await this.loadConnectedTreeNodes();
  },
  methods: {
    async loadConnectedTreeNodes() {
      try {
        // Get the ancestors to get the object
        const { data } = await this.$apollo.query({
          query: queryGet,
          fetchPolicy: 'network-only',
          variables: { id: this.treeNodeId },
        });
        if (data?.gateways?.first?.deviceMounts?.items) {
          this.connectedDevices = data?.gateways?.first?.deviceMounts?.items.map(
            (item: GatewaySpots_gateways_first_deviceMounts_items) => {
              return item.spot;
            },
          );
        }
        // Get the count
        if (data?.gateways?.first?.deviceMounts?.count) {
          this.totalPages = Math.ceil(data?.gateways?.first?.deviceMounts?.count / this.pageSize);
        }
        this.loading = false;
      } catch (error) {
        console.log('error', error);
      }
    },
  },
});
