

















import { GatewayHealthQueryStringMixin } from '@/features/core/components/mixins/gateway-health-query-string';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import gatewaysQuery from './gateways.gql';
import query from './view.gql';
import {
  AppManagerTreeNodeGatewaysViewGatewaysQuery,
  AppManagerTreeNodeGatewaysViewGatewaysQueryVariables,
} from './__generated__/AppManagerTreeNodeGatewaysViewGatewaysQuery';
import {
  AppManagerTreeNodeGatewaysViewQuery,
  AppManagerTreeNodeGatewaysViewQueryVariables,
} from './__generated__/AppManagerTreeNodeGatewaysViewQuery';

@Component({
  apollo: {
    treeNodes: {
      query,
      pollInterval: 60000,
      variables(this: GatewaysView): AppManagerTreeNodeGatewaysViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
    gateways: {
      query: gatewaysQuery,
      pollInterval: 60000,
      variables(this: GatewaysView): AppManagerTreeNodeGatewaysViewGatewaysQueryVariables {
        return {
          treeNodeId: this.treeNodeId,
          take: this.take,
          skip: this.skip,
          healths: this.health === undefined ? undefined : [this.health],
          searchTerm: this.searchTerm,
        };
      },
    },
  },
  data() {
    return { treeNodes: undefined, gateways: undefined };
  },
})
export default class GatewaysView extends Mixins(GatewayHealthQueryStringMixin, PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeGatewaysViewQuery['treeNodes'];
  private readonly gateways?: AppManagerTreeNodeGatewaysViewGatewaysQuery['gateways'];

  protected get count(): number {
    return this.gateways && 'gateways' in this.gateways.first ? this.gateways.first.gateways.count : 0;
  }

  protected get searchTerm(): string | undefined {
    return (this.$route.query.searchTerm as string) ?? undefined;
  }

  protected set searchTerm(searchTerm: string | undefined) {
    const query = { ...this.$route.query }; // Make a copy of the current query object

    if (searchTerm === this.searchTerm || !searchTerm || searchTerm.length < 3) {
      delete query.searchTerm; // Remove the searchTerm from the query object
    } else {
      query.searchTerm = searchTerm; // Update the searchTerm in the query object
    }

    // Replace the current route with the updated query object
    void this.$router.replace({ query });
  }
}
