










import { Component, Vue } from 'vue-property-decorator';
import { DateProp, StringProp } from '@/util/prop-decorators';
import {
  AppManagerTreeNodePropertyHeatAllocatorsVariables,
  AppManagerTreeNodePropertyHeatAllocators_spots,
} from './__generated__/AppManagerTreeNodePropertyHeatAllocators';
import { HeatConsumptionAttributesType } from '@/features/domain-ui/eed-consumption/model';
import query from './heat-cost-allocators.gql';

@Component({
  apollo: {
    spots: {
      query,
      variables(this: PropertyHeatConsumption): AppManagerTreeNodePropertyHeatAllocatorsVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { spots: undefined };
  },
})
export default class PropertyHeatConsumption extends Vue {
  @StringProp()
  private readonly treeNodeId!: string;

  @DateProp()
  private readonly period!: Date;

  private spots?: AppManagerTreeNodePropertyHeatAllocators_spots;

  private get hasCorrectConfig(): boolean {
    if (!this.spots) {
      return false;
    }
    const spotsAttributes = this.spots.items.map(({ attributes }) =>
      attributes.find(
        ({ configuration }) =>
          configuration.name === HeatConsumptionAttributesType.CONVERSION_FACTOR ||
          configuration.name === HeatConsumptionAttributesType.BASE_SENSITIVITY,
      ),
    );
    if (!spotsAttributes || spotsAttributes.length == 0) {
      return false;
    }
    return spotsAttributes.find((attribute) => attribute?.value) !== undefined;
  }
}
