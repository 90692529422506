












import { StringProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BuildingSubdivision extends Vue {
  @StringProp(true)
  private readonly label!: string;

  @BooleanProp()
  private readonly alert!: boolean;

  @BooleanProp()
  private readonly invertColor!: boolean;
}
