export const enum ScrollBehavior {
  CONTAIN = 'CONTAIN',
  DOWN = 'DOWN',
}

export default function scrollToElement(element?: Element, offset = 0, behavior = ScrollBehavior.CONTAIN): void {
  if (!element) {
    return;
  }

  const { top, bottom } = element.getBoundingClientRect();

  const [up, down] =
    behavior === ScrollBehavior.CONTAIN
      ? [top - offset, bottom + offset - document.documentElement.clientHeight]
      : [0, top - offset];

  const currentScrollTop = window.scrollY !== undefined ? window.scrollY : window.pageYOffset;
  const scrollTop = up < 0 ? currentScrollTop + up : down > 0 ? currentScrollTop + down : undefined;

  window.scroll({ top: scrollTop, behavior: 'smooth' });
}
