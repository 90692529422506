



































import { defineComponent } from '@vue/composition-api';
import { AppManagerGatewayConfigurationPanelGatewayConfigurationFragment } from '@/features/app-manager/components/gateway-configuration-panel/__generated__/AppManagerGatewayConfigurationPanelGatewayConfigurationFragment';

interface DataConsumptionPollPanelProps {
  gatewayId: string;
}
type ConfigurationValue = string | number | boolean | null;
type ConfigurationProperty = {
  name: keyof AppManagerGatewayConfigurationPanelGatewayConfigurationFragment;
  group: string;
  label: string;
};
type ConfigurationEntry = ConfigurationProperty & { value: ConfigurationValue; target: ConfigurationValue };

const PROPERTIES: ConfigurationProperty[] = [
  { name: 'accessPointExternalEnabled', group: 'WLAN', label: 'Aktiviert' },
  { name: 'accessPointExternalSsid', group: 'WLAN', label: 'SSID' },
  { name: 'accessPointExternalPreSharedKey', group: 'WLAN', label: 'Schlüssel' },
  { name: 'lightBrightness', group: 'Licht', label: ' Helligkeit' },
  { name: 'lightDuration', group: 'Licht', label: 'Dauer (in Sekunden)' },
  { name: 'lightEmergencyBrightness', group: 'Notlicht', label: 'Helligkeit' },
  { name: 'lightEmergencyTimeout', group: 'Notlicht', label: 'Max. Unterbrechungsdauer (in Sekunden)' },
  { name: 'lightEmergencyInterval', group: 'Notlicht', label: 'Prüfintervall (in Sekunden)' },
  { name: 'lightPingInterval', group: 'Notlicht', label: 'Ping-Intervall (in Sekunden)' },
];
export default defineComponent<DataConsumptionPollPanelProps, Record<string, unknown>, any, any>({
  components: {},
  props: {
    gateway: {
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      columns: [
        { name: 'group', label: 'Gruppe' },
        { name: 'label', label: 'Option' },
        { name: 'value', label: 'Aktueller Wert', align: 'right' },
        { name: 'target', label: 'Soll-Wert', align: 'right' },
        { name: 'state', label: '' },
      ],
    };
  },
  computed: {
    configurationEntries(): ConfigurationEntry[] {
      const { configuration, gatewayTargetConfiguration } = this.gateway;

      return PROPERTIES.map(({ name, ...rest }) => ({
        name,
        ...rest,
        value: configuration === null ? null : configuration[name],
        target: name in gatewayTargetConfiguration ? gatewayTargetConfiguration[name] : null,
      }));
    },
  },
  methods: {
    formatValue(value: string | number | boolean | null): string {
      switch (value) {
        case null:
          return '–';
        case true:
          return 'Ja';
        case false:
          return 'Nein';
        default:
          return String(value);
      }
    },
  },
});
