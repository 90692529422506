





import { TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component as ComponentType } from 'vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  AppManagerTreeNodeViewQuery,
  AppManagerTreeNodeViewQueryVariables,
} from './__generated__/AppManagerTreeNodeViewQuery';
import ContentDirectory from './ContentDirectory.vue';
import ContentProperty from './ContentProperty.vue';
import ContentPropertySubdivision from './ContentPropertySubdivision.vue';
import query from './view.gql';
import BlankContent from '@/features/app-manager/views/tree-node/BlankContent.vue';

const CONTENT: Record<TreeNodeType, ComponentType | undefined> = {
  [TreeNodeType.RootDirectory]: ContentDirectory,
  [TreeNodeType.Directory]: ContentDirectory,
  [TreeNodeType.PropertyGroup]: ContentDirectory,
  [TreeNodeType.Property]: ContentProperty,
  [TreeNodeType.PropertySubdivision]: ContentPropertySubdivision,
  [TreeNodeType.Gateway]: BlankContent,
  [TreeNodeType.Spot]: undefined,
  [TreeNodeType.PropertySpot]: undefined,
  [TreeNodeType.TreeNodeGroup]: undefined,
};

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: TreeNodeView): AppManagerTreeNodeViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class TreeNodeView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeViewQuery['treeNodes'];

  private get scrollToTop(): boolean {
    return (
      this.treeNodes !== undefined &&
      this.treeNodes.first.__typename === 'Property' &&
      this.$route.name === 'AppManager/TreeNode'
    );
  }

  private get content(): ComponentType | undefined {
    return CONTENT[this.treeNodes?.first.__typename ?? TreeNodeType.Spot];
  }

  @Watch('treeNodes')
  private checkContentAvailable(): void {
    if (this.treeNodes === undefined || this.content !== undefined) {
      return;
    }

    const ancestor = [...this.treeNodes.first.path.items]
      .reverse()
      .find(({ __typename }) => CONTENT[TreeNodeType[__typename]] !== undefined);
    const location = ancestor === undefined ? { name: 'AppHome' } : { params: { treeNodeId: ancestor.id } };

    this.$router.replace(location);
  }
}
