















































import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import ScrollToRefMixin from '@/features/ui/mixins/scroll-to-ref';
import { ScrollBehavior } from '@/util/scroll-to-element';
import { GatewayHealth, SpotHealth } from '@/types/iot-portal';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Mixins } from 'vue-property-decorator';
import { merge } from 'lodash';
import { AppManagerAppViewQuery, AppManagerAppViewQueryVariables } from './__generated__/AppManagerAppViewQuery';
import { AppManagerPropertiesQuery } from './__generated__/AppManagerPropertiesQuery';
import query from './view.gql';
import propertiesQuery from './properties.gql';
import alertsQuery from './alerts.gql';
import GroupsMixin from '@/features/core/components/mixins/groups';
import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import { RootGetter } from '@/features/core/store';
import { AppManagerPropertiesAlertsQuery } from './__generated__/AppManagerPropertiesAlertsQuery';

@Component({
  apollo: {
    summaryData: {
      query,
      manual: true,
      result(this: AppView, { data }: ApolloQueryResult<AppManagerAppViewQuery>): void {
        this.spots = data.spots;
        this.gateways = data.gateways;
      },
      variables(this: AppView): AppManagerAppViewQueryVariables {
        return {
          userId: this.userId,
        };
      },
    },
    properties: {
      query: propertiesQuery,
      manual: true,
      result(this: AppView, { data }: ApolloQueryResult<AppManagerPropertiesQuery>): void {
        this.properties = merge(data.properties, this.properties || ({} as AppManagerPropertiesQuery['properties']));
      },
    },
    alerts: {
      query: alertsQuery,
      manual: true,
      result(this: AppView, { data }: ApolloQueryResult<AppManagerPropertiesAlertsQuery>): void {
        this.properties = merge(data.properties, this.properties || ({} as AppManagerPropertiesQuery['properties']));
      },
    },
  },
  data() {
    return { gateways: { healthAggregations: [] }, spots: { healthAggregations: [] }, properties: { items: [] } };
  },
})
export default class AppView extends Mixins(
  TreeNodeLocationGeneratorMixin,
  ScrollToRefMixin,
  AppRedirectionMixin,
  GroupsMixin,
) {
  private spots!: AppManagerAppViewQuery['spots'];
  private gateways!: AppManagerAppViewQuery['gateways'];
  private properties!: AppManagerPropertiesQuery['properties'];

  @RootGetter
  private readonly userId?: string;

  private onSpotSummaryDetailsClick(health?: SpotHealth): void {
    const query = health === undefined ? undefined : { health };
    this.$router.push({ name: 'AppManager/Spots', query });

    this.scrollToRef('content', 20, ScrollBehavior.DOWN);
  }

  private onGatewaySummaryDetailsClick(health?: GatewayHealth): void {
    const query = health === undefined ? undefined : { health };
    this.$router.push({ name: 'AppManager/Gateways', query });

    this.scrollToRef('content', 20, ScrollBehavior.DOWN);
  }
}
