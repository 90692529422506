var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-collapsible-panel',{attrs:{"title":"Einstellungen"}},[(_vm.spots)?_c('ui-form',{attrs:{"initial-data":_vm.initialData,"action":_vm.editSpot},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var formData = ref.formData;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"input.name","label":"Name"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"input.roomName","label":"Raum"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-separator')],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-textarea","path":"input.notes","label":"Notizen"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-separator')],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-headline',[_vm._v("Metriken")])],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table',{attrs:{"rows":_vm.metrics,"columns":_vm.metricColumns},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var row = ref.row;
return [_c('ui-form-field',{attrs:{"input":"ui-input-text","path":("metricConfigurations." + (row.name) + ".label"),"placeholder":row.defaultLabel}})]}},{key:"shortLabel",fn:function(ref){
var row = ref.row;
return [_c('ui-form-field',{attrs:{"input":"ui-input-text","path":("metricConfigurations." + (row.name) + ".shortLabel")}})]}},{key:"position",fn:function(ref){
var row = ref.row;
return [_c('ui-form-field',{attrs:{"input":"ui-input-text","path":("metricConfigurations." + (row.name) + ".position")}})]}}],null,true)})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-separator')],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,false,2752414623)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }