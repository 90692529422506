var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',{staticClass:"box detail-box"},[(_vm.treeNode)?[_c('ui-wizard',{attrs:{"title":"Dokument hochladen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Dokument hochladen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"initial-data":{},"action":_vm.uploadTreeNodeFile},on:{"submitted":hide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var formData = ref.formData;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"label","label":"Name (optional)"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-file","path":"file","accept":"image/*, audio/*, video/*,.pdf","label":"Datei"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !formData.file,"busy":busy},on:{"click":submit}},[_vm._v("Hochladen")])],1)],1)]}}],null,true)})]}}],null,false,1978268337)})]:_vm._e(),(_vm.treeNode)?[_c('ui-flex',[(_vm.totalPages > 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('domain-ui-tree-node-file-table',{attrs:{"files":_vm.treeNode.files.items},on:{"remove":_vm.deleteTreeNodeFile,"download":_vm.downloadTreeNodeFile}}),_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Es wurden bisher keine Dokumente hinterlegt.")])],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }