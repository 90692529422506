















import { StringProp } from '@/util/prop-decorators';
import { Vue, Component } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppManagerActivityLogEntryAttributesViewQuery,
  AppManagerActivityLogEntryAttributesViewQueryVariables,
} from './__generated__/AppManagerActivityLogEntryAttributesViewQuery';
import ActivityLogEntryAttributeControl from './activity-log-entry-attribute-control/ActivityLogEntryAttributeControl.vue';

type Attribute =
  AppManagerActivityLogEntryAttributesViewQuery['treeNodes']['first']['activityLogEntries']['first']['attributes'][number];

@Component({
  components: { ActivityLogEntryAttributeControl },
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: AttributesView): AppManagerActivityLogEntryAttributesViewQueryVariables {
        const { treeNodeId, activityLogEntryId } = this;
        return { treeNodeId, activityLogEntryId };
      },
    },
  },
  data() {
    return {
      treeNodes: undefined,
    };
  },
})
export default class AttributesView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @StringProp(true)
  private readonly activityLogEntryId!: string;

  private readonly treeNodes?: AppManagerActivityLogEntryAttributesViewQuery['treeNodes'];

  private get attributes(): Attribute[] {
    return this.treeNodes?.first.activityLogEntries.first.attributes ?? [];
  }
}
