<template>
  <div class="device-info">
    <JsonItem :name="'root'" :property-value="data" />
  </div>
</template>

<script>
import JsonItem from './JsonItem.vue';

export default {
  name: 'JSONObject',
  components: {
    JsonItem,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.device-info {
  font-family: Arial, sans-serif;
  line-height: 1.5;
}

.device-info h3 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.device-info ul,
.device-info ol {
  margin: 0;
  padding: 0;
}

.device-info li {
  margin-bottom: 5px;
}
</style>
