




















import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppManagerTreeNodeInventoryViewQuery,
  AppManagerTreeNodeInventoryViewQueryVariables,
} from './__generated__/AppManagerTreeNodeInventoryViewQuery';
import query from './view.gql';

interface InventoryRow {
  name: string;
  count: number;
  location: string;
}

@Component({
  apollo: {
    treeNodes: {
      query,
      pollInterval: 60000,
      variables(this: InventoryView): AppManagerTreeNodeInventoryViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class InventoryView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeInventoryViewQuery['treeNodes'];

  private readonly columns: Column[] = [
    { name: 'index', label: 'Spot ID' },
    { name: 'category', label: 'Kategorie' },
    { name: 'location', label: 'Lage' },
    { name: 'name', label: 'Beschreibung' },
    { name: 'details', label: 'Details' },
    { name: 'link', label: '' },
  ];

  private get inventory(): InventoryRow[] {
    return (
      this.treeNodes?.items.flatMap((treeNode) =>
        'inventory' in treeNode
          ? treeNode.inventory?.items.map((item) => ({ ...item, location: treeNode.name })) ?? []
          : [],
      ) ?? []
    );
  }
}
