





















import { defineComponent } from '@vue/composition-api';
import gatewayDetailsQuery from '@/features/app-manager/views/tree-node/gateway-new-details/gatewayDetails.gql';
import GatewayDetailBreakdown from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailBreakdown.vue';
import GatewayDetailReplacementHistory from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailReplacementHistory.vue';
import ConnectedDevices from '@/features/core/components/connected-devices/ConnectedDevices.vue';
import LoadingIndicator from '@/features/core/components/loading-indicator/LoadingIndicator.vue';

interface GatewayDetailsOverviewProps {
  treeNodeId: string;
}
export default defineComponent<GatewayDetailsOverviewProps, Record<string, unknown>, any>({
  components: { LoadingIndicator, ConnectedDevices, GatewayDetailReplacementHistory, GatewayDetailBreakdown },
  props: {
    treeNodeId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      gatewayDetails: null,
      property: null,
      isLoading: true,
      hasError: false,
      errorMessage: undefined,
    };
  },
  computed: {},
  async created() {
    await this.loadGateway();
  },
  methods: {
    async loadGateway() {
      try {
        const { data } = await this.$apollo.query({
          query: gatewayDetailsQuery,
          fetchPolicy: 'network-only',
          variables: { id: this.treeNodeId },
        });
        if (data?.gateways?.first) {
          this.gatewayDetails = data.gateways.first;
          // Assign the property
          if (this.gatewayDetails?.parent?.parent) {
            this.property = this.gatewayDetails?.parent?.parent;
          }
        }
      } catch (error) {
        this.errorMessage = error;
      }
      this.isLoading = false;
    },
  },
});
