









import { ActivityLogEntryTableRow } from '@/features/domain-ui/activity-log-entry-table/model';
import { TREE_NODE_TYPE_META } from '@/features/domain-ui/tree-node-type/constants';
import { ExportColumn } from '@/features/ui/data-export/model';
import { TreeNodeType } from '@/types/iot-portal';
import { ArrayProp, BooleanProp } from '@/util/prop-decorators';
import { groupBy } from 'lodash';
import moment, { Moment } from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { ActivityLogEntriesExportRow } from './model';

@Component
export default class ActivityLogEntriesExport extends Vue {
  @ArrayProp(() => [])
  private readonly activityLogEntries!: ActivityLogEntryTableRow[];

  @BooleanProp()
  private readonly disabled!: boolean;

  private readonly baseColumns: ExportColumn[] = [
    {
      name: 'checkInDate',
      accessor: 'activityLogEntry.checkInDate',
      label: 'Beginn',
      formatText: (row, column, value) => {
        return moment(value as Date).format('L LT');
      },
    },
    { name: 'endDate', accessor: 'endDate', label: 'Ende' },
    { name: 'activityName', accessor: 'activityLogEntry.activityName', label: 'Aktivität' },
    { name: 'pinLabel', accessor: 'activityLogEntry.pinLabel', label: 'PIN' },
  ];

  private get pathColumns(): ExportColumn[] {
    const counts: Partial<Record<TreeNodeType, number>> = {};

    for (const { pathTypes } of this.rows) {
      for (const [type, { length }] of Object.entries(pathTypes) as [TreeNodeType, string[]][]) {
        counts[type] = Math.max(counts[type] ?? 0, length);
      }
    }

    function* generateColumns(type: TreeNodeType, count: number): Generator<ExportColumn> {
      for (let i = 0; i < count; i++) {
        yield {
          name: type + (count > 1 ? i + 1 : ''),
          label: TREE_NODE_TYPE_META[type].label + (count > 1 ? ` ${i + 1}` : ''),
          accessor: `pathTypes.${type}[${i}]`,
        };
      }
    }

    return Object.entries(counts).flatMap(([type, count]) => [...generateColumns(type as TreeNodeType, count ?? 0)]);
  }

  private get exportColumns(): ExportColumn[] {
    return this.baseColumns.concat(this.pathColumns);
  }

  private get rows(): ActivityLogEntriesExportRow[] {
    return this.activityLogEntries.map((activityLogEntry) => ({
      activityLogEntry,
      pathTypes: Object.fromEntries(
        Object.entries(groupBy(activityLogEntry.treeNode.path.items, '__typename')).map(([type, nodes]) => [
          type,
          nodes.map(({ name }) => name),
        ]),
      ) as Record<TreeNodeType, string[]>,
      endDate:
        activityLogEntry.latestCheckOutDate === null
          ? '–'
          : activityLogEntry.checkOutDate === null
          ? 'Abgelaufen'
          : moment(activityLogEntry.checkOutDate).format('L LT'),
    }));
  }

  private now(): Moment {
    return moment();
  }
}
