
































































import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { RootGetter } from '@/features/core/store';
import ScrollToRefMixin from '@/features/ui/mixins/scroll-to-ref';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppManagerTreeNodeViewContentPropertySubdivisionQuery,
  AppManagerTreeNodeViewContentPropertySubdivisionQueryVariables,
} from './__generated__/AppManagerTreeNodeViewContentPropertySubdivisionQuery';
import query from './content-property-subdivision.gql';
import SpotDetailsView from './spot-details/SpotDetailsView.vue';
import GroupsMixin from '@/features/core/components/mixins/groups';
import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';

const TABS: readonly string[] = [
  'AppManager/TreeNode',
  'AppManager/TreeNode/Spots/Spot',
  'AppManager/TreeNode/Spots',
  'AppManager/TreeNode/Alerts',
  'AppManager/TreeNode/ActivityLogEntries',
  'AppManager/TreeNode/GroundPlan',
  'AppManager/TreeNode/Inventory',
  'AppManager/TreeNode/Gateways',
  'AppManager/TreeNode/Gateways/GatewayConfigurationPreset',
  'AppManager/TreeNode/Gateways/Details',
  'AppManager/TreeNode/Gateways/Details/GatewayConfigurationPreset',
  'AppManager/TreeNode/Files',
  'AppManager/TreeNode/Attributes',
];

@Component({
  apollo: {
    propertySubdivisions: {
      query,
      pollInterval: 60000,
      variables(this: ContentPropertySubdivision): AppManagerTreeNodeViewContentPropertySubdivisionQueryVariables {
        return { treeNodeId: this.treeNodeId, userId: this.userId };
      },
    },
  },
  data() {
    return { propertySubdivisions: undefined };
  },
})
export default class ContentPropertySubdivision extends Mixins(
  TreeNodeLocationGeneratorMixin,
  ScrollToRefMixin,
  AppRedirectionMixin,
  GroupsMixin,
) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootGetter
  private readonly admin!: boolean;

  @RootGetter
  private readonly technician!: boolean;

  @RootGetter
  private readonly userId?: string;

  private readonly propertySubdivisions?: AppManagerTreeNodeViewContentPropertySubdivisionQuery['propertySubdivisions'];

  private readonly defaultView = SpotDetailsView;

  private get inventoryCount(): number {
    return (
      this.propertySubdivisions?.first.tree.items.reduce(
        (sum, item) => sum + ('inventory' in item && item.inventory !== null ? item.inventory.items.length : 0),
        0,
      ) ?? 0
    );
  }

  private created(): void {
    if (TABS.includes(this.$route.name ?? '')) {
      return;
    }

    this.$router.replace({ name: 'AppManager/TreeNode' });
  }

  private onSpotSummaryDetailsClick(): void {
    this.$router.push({ name: 'AppManager/TreeNode' });

    this.scrollToRef('content');
  }

  private matchTabValue(a: string, b: string): boolean {
    return (
      a === b ||
      (b === 'AppManager/TreeNode' && a === 'AppManager/TreeNode/Spots/Spot') ||
      (b === 'AppManager/TreeNode/Gateways' && a === 'AppManager/TreeNode/Gateways/GatewayConfigurationPreset') ||
      (b === 'AppManager/TreeNode/Gateways' && a === 'AppManager/TreeNode/Gateways/Details') ||
      (b === 'AppManager/TreeNode/Gateways' && a === 'AppManager/TreeNode/Gateways/Details/GatewayConfigurationPreset')
    );
  }
}
