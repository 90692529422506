












































































































































import { RootGetter } from '@/features/core/store';
import { SetGatewayConfigurationPresetInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import applyConfigurationMutation from './apply-configuration.gql';
import editPresetMutation from './edit-preset.gql';
import query from './tree-nodes.gql';
import {
  AppManagerEditGatewayConfigurationPresetPanelControlApplyConfigurationMutation,
  AppManagerEditGatewayConfigurationPresetPanelControlApplyConfigurationMutationVariables,
} from './__generated__/AppManagerEditGatewayConfigurationPresetPanelControlApplyConfigurationMutation';
import {
  AppManagerEditGatewayConfigurationPresetPanelControlEditMutation,
  AppManagerEditGatewayConfigurationPresetPanelControlEditMutationVariables,
} from './__generated__/AppManagerEditGatewayConfigurationPresetPanelControlEditMutation';
import { AppManagerEditGatewayConfigurationPresetPanelControlGatewaySettingsFragment } from './__generated__/AppManagerEditGatewayConfigurationPresetPanelControlGatewaySettingsFragment';
import {
  AppManagerEditGatewayConfigurationPresetPanelControlQuery,
  AppManagerEditGatewayConfigurationPresetPanelControlQueryVariables,
} from './__generated__/AppManagerEditGatewayConfigurationPresetPanelControlQuery';

type FormData = Omit<SetGatewayConfigurationPresetInput, 'treeNodeId' | 'apply'>;

@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(
        this: EditGatewayConfigurationPresetPanelControl,
      ): AppManagerEditGatewayConfigurationPresetPanelControlQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class EditGatewayConfigurationPresetPanelControl extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootGetter
  private readonly admin!: boolean;

  private readonly treeNodes?: AppManagerEditGatewayConfigurationPresetPanelControlQuery['treeNodes'];

  private get initialData(): Partial<FormData> {
    return this.treeNodes?.first.gatewayConfigurationPreset ?? {};
  }

  private get placeholders(): Partial<
    Record<keyof AppManagerEditGatewayConfigurationPresetPanelControlGatewaySettingsFragment, string>
  > {
    return Object.fromEntries(
      Object.entries(this.treeNodes?.first.gatewayTargetConfiguration ?? {})
        .filter(([, value]) => value !== null)
        .map(([key, value]) => [
          key,
          `Voreinstellung: ${key === 'accessPointExternalEnabled' ? (value ? 'Aktiviert' : 'Deaktiviert') : value}`,
        ]),
    );
  }

  private async executeFormAction(input: FormData, dirty = false): Promise<void> {
    await (dirty ? this.editPreset(input) : this.applyConfiguration());
  }

  private async applyConfiguration(): Promise<void> {
    await this.$apollo.mutate<
      AppManagerEditGatewayConfigurationPresetPanelControlApplyConfigurationMutation,
      AppManagerEditGatewayConfigurationPresetPanelControlApplyConfigurationMutationVariables
    >({
      mutation: applyConfigurationMutation,
      variables: { input: { treeNodeId: this.treeNodeId } },
    });
  }

  private async editPreset(input: FormData): Promise<void> {
    await this.$apollo.mutate<
      AppManagerEditGatewayConfigurationPresetPanelControlEditMutation,
      AppManagerEditGatewayConfigurationPresetPanelControlEditMutationVariables
    >({
      mutation: editPresetMutation,
      variables: {
        input: {
          ...input,
          treeNodeId: this.treeNodeId,
          apply: true,
        },
      },
    });
  }
}
