










import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppManagerBuildingGatewayFragment } from './__generated__/AppManagerBuildingGatewayFragment';

@Component({ inheritAttrs: false })
export default class BuildingGateway extends Vue {
  @ObjectProp(true)
  private readonly gateway!: AppManagerBuildingGatewayFragment;
}
