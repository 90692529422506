import { render, staticRenderFns } from "./GatewayDetailsView.vue?vue&type=template&id=ecadb576&lang=pug&"
import script from "./GatewayDetailsView.vue?vue&type=script&lang=ts&"
export * from "./GatewayDetailsView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports