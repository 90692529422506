


























import { defineComponent } from '@vue/composition-api';
import query from '@/features/app-manager/views/tree-node/gateway-details/view.gql';
import GatewayDetailsConfiguration from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailsConfiguration.vue';
import GatewayDetailsFiles from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailsFiles.vue';
import GatewayDetailsNotifications from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailsNotifications.vue';
import TreeNodeAttributes from '@/features/app-manager/components/tree-node-attributes/TreeNodeAttributes.vue';
import GatewayDetailsDetails from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailsDetails.vue';
import GatewayDetailOverview from '@/features/app-manager/views/tree-node/gateway-new-details/components/GatewayDetailOverview.vue';

interface GatewayDetailsViewProps {
  customerId: string;
}

export default defineComponent<GatewayDetailsViewProps, Record<string, unknown>, any, any>({
  components: {
    GatewayDetailOverview,
    TreeNodeAttributes,
    GatewayDetailsDetails,
    GatewayDetailsNotifications,
    GatewayDetailsFiles,
    GatewayDetailsConfiguration,
  },
  props: {
    gatewayId: {
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      gateway: undefined,
      tab: 'Übersicht',
    };
  },
  computed: {},
  async created() {
    await this.loadGateway();
  },
  methods: {
    async loadGateway() {
      try {
        const { data, errors } = await this.$apollo.query({
          query: query,
          fetchPolicy: 'network-only',
          variables: {
            ids: this.gatewayId,
          },
        });
        if (data?.gateways?.first && !errors) {
          this.gateway = data.gateways.first;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    updateTab(tab: string) {
      this.tab = tab;
    },
  },
});
