










import { defineComponent } from '@vue/composition-api';
import query from '@/features/app-manager/views/tree-node/tree-node-path.gql';
import { mapGetters } from 'vuex';
import SpotDetailsView from '@/features/app-manager/views/tree-node/spot-details/SpotDetailsView.vue';

export default defineComponent<Record<string, unknown>, any, any>({
  components: {},
  props: {
    treeNodeId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      treeNode: undefined,
      defaultView: SpotDetailsView,
    };
  },
  computed: {
    ...mapGetters(['userId']),
  },
  async created() {
    await this.loadTreeNode();
  },
  methods: {
    async loadTreeNode() {
      const { data } = await this.$apollo.query({
        query: query,
        fetchPolicy: 'network-only',
        variables: { id: this.treeNodeId },
      });
      if (data?.treeNodes?.first) {
        this.treeNode = data.treeNodes.first;
      }
    },
  },
});
