








































































import { API_ENDPOINT } from '@/env';
import ContainerMixin from '@/features/core/components/mixins/container';
import { KEYCLOAK } from '@/features/core/container';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import ImageCanvas from '@/features/ui/image-canvas/ImageCanvas.global.vue';
import { Placement } from '@/features/ui/image-canvas/model';
import { RelativePositionInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import GroundPlanSpotPlacement from './GroundPlanSpotPlacement.vue';
import placeGroundPlanSpotMutation from './place-ground-plan-spot.gql';
import setGroundPlanFileMutation from './set-ground-plan-file.gql';
import query from './view.gql';
import {
  AppManagerTreeNodeGroundPlanPlaceGroundPlanSpotMutation,
  AppManagerTreeNodeGroundPlanPlaceGroundPlanSpotMutationVariables,
} from './__generated__/AppManagerTreeNodeGroundPlanPlaceGroundPlanSpotMutation';
import {
  AppManagerTreeNodeGroundPlanSetGroundPlanFileMutation,
  AppManagerTreeNodeGroundPlanSetGroundPlanFileMutationVariables,
} from './__generated__/AppManagerTreeNodeGroundPlanSetGroundPlanFileMutation';
import { AppManagerTreeNodeGroundPlanViewGroundPlanFragment } from './__generated__/AppManagerTreeNodeGroundPlanViewGroundPlanFragment';
import { AppManagerTreeNodeGroundPlanViewPlacementFragment } from './__generated__/AppManagerTreeNodeGroundPlanViewPlacementFragment';
import {
  AppManagerTreeNodeGroundPlanViewQuery,
  AppManagerTreeNodeGroundPlanViewQueryVariables,
  AppManagerTreeNodeGroundPlanViewQuery_treeNodes_first_Gateway_files_items,
} from './__generated__/AppManagerTreeNodeGroundPlanViewQuery';

@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: GroundPlanView): AppManagerTreeNodeGroundPlanViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined, editable: false, imageSrc: undefined };
  },
  components: { GroundPlanSpotPlacement },
})
export default class GroundPlanView extends Mixins(ContainerMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly treeNodes?: AppManagerTreeNodeGroundPlanViewQuery['treeNodes'];

  private editable!: boolean;
  private imageSrc?: string;

  public readonly $refs!: { imageCanvas: ImageCanvas<AppManagerTreeNodeGroundPlanViewPlacementFragment> };

  private get groundPlan(): AppManagerTreeNodeGroundPlanViewGroundPlanFragment | undefined {
    if (!this.treeNodes || !('groundPlanFile' in this.treeNodes.first)) {
      return undefined;
    }

    return this.treeNodes.first.groundPlanFile ?? undefined;
  }

  private get groundPlanFileId(): string | undefined {
    return this.groundPlan?.id;
  }

  private get groundPlanLabel(): string | null {
    const groundPlanFile = this.treeNodesFiles?.find(({ id }) => id === this.groundPlan?.id);
    return groundPlanFile?.label ?? null;
  }

  private get treeNodesFiles():
    | AppManagerTreeNodeGroundPlanViewQuery_treeNodes_first_Gateway_files_items[]
    | undefined {
    return this.treeNodes?.first.files.items;
  }

  private get placements(): Placement<AppManagerTreeNodeGroundPlanViewPlacementFragment>[] {
    const placements = this.groundPlan?.spotPlacements ?? [];

    return placements.map((value) => ({
      key: value.id,
      value,
      position: 'position' in value ? value.position : undefined,
    }));
  }

  private get unpositionedPlacements(): AppManagerTreeNodeGroundPlanViewPlacementFragment[] {
    const placements = this.groundPlan?.spotPlacements ?? [];

    return placements.filter(({ __typename }) => __typename === 'GroundPlanUnpositionedSpotPlacement');
  }

  @Watch('groundPlanFileId', { immediate: true })
  private async fetchImage(id: string | undefined): Promise<void> {
    if (id === undefined) {
      this.imageSrc = undefined;

      return;
    }

    const endpoint = new URL(API_ENDPOINT, window.location.href);
    endpoint.username = '';
    endpoint.password = '';
    const url = new URL(`api/file/${id}`, endpoint);
    const response = await fetch(url.toString(), {
      headers: { Authorization: `Bearer ${this.container(KEYCLOAK).token || ''}` },
    });

    const reader = new FileReader();
    reader.onloadend = () => {
      if (this.groundPlanFileId === id) {
        this.imageSrc = reader.result?.toString();
      }
    };
    reader.readAsDataURL(await response.blob());
  }

  private async setGroundPlanFile(treeNodeFileId: string | null): Promise<void> {
    try {
      const { data } = await this.$apollo.mutate<
        AppManagerTreeNodeGroundPlanSetGroundPlanFileMutation,
        AppManagerTreeNodeGroundPlanSetGroundPlanFileMutationVariables
      >({
        mutation: setGroundPlanFileMutation,
        variables: {
          input: {
            treeNodeId: this.treeNodeId,
            treeNodeFileId: treeNodeFileId,
          },
        },
      });

      if (!data) {
        throw new Error('Data missing');
      }

      this.editable = treeNodeFileId !== null;
    } catch (e) {
      // eslint-disable-next-line no-console -- dont swallow errors
      console.error(e);

      const text = `Der Grundriss konnte nicht ${treeNodeFileId === null ? 'entfernt' : 'gesetzt'} werden.`;
      this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
    }
  }

  private async placeGroundPlanSpot(spotId: string, position: RelativePositionInput | null): Promise<void> {
    if (!this.groundPlanFileId) {
      return;
    }

    try {
      const { data } = await this.$apollo.mutate<
        AppManagerTreeNodeGroundPlanPlaceGroundPlanSpotMutation,
        AppManagerTreeNodeGroundPlanPlaceGroundPlanSpotMutationVariables
      >({
        mutation: placeGroundPlanSpotMutation,
        variables: {
          input: {
            treeNodeFileId: this.groundPlanFileId,
            spotId,
            position,
          },
        },
        // optimisticResponse
        update: (store, { data: spotPlacementData }) => {
          const viewData = store.readQuery<AppManagerTreeNodeGroundPlanViewQuery>({
            query,
            variables: { treeNodeId: this.treeNodeId },
          });
          if (!viewData || !spotPlacementData || !('groundPlanFile' in viewData.treeNodes.first)) {
            return;
          }
          const existingSpotPlacement = viewData.treeNodes.first.groundPlanFile?.spotPlacements.find(
            (spotPlacement) => spotPlacement.spot.id === spotPlacementData.placeGroundPlanSpot.placement.spot.id,
          );
          if (!existingSpotPlacement) {
            viewData?.treeNodes.first.groundPlanFile?.spotPlacements.push(
              spotPlacementData.placeGroundPlanSpot.placement,
            );
          }
          store.writeQuery({ query, data: viewData });
        },
      });
      if (!data) {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: 'Die Position konnte nicht gespeichert werden.' }],
        });
        return;
      }
    } catch (e) {
      // eslint-disable-next-line no-console -- dont swallow errors
      console.error(e);

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Die Position konnte nicht gespeichert werden.' }],
      });
    }
  }

  private routeToSpotPage(spot: AppManagerTreeNodeGroundPlanViewPlacementFragment['spot']): void {
    this.$router.push({
      name: 'AppManager/TreeNode/Spots/Spot',
      params: {
        treeNodeId: spot.path.items.slice(-1)[0].id,
        spotId: spot.id,
      },
    });
  }
}
