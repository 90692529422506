









import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { ObjectProp, StringProp } from '@/util/prop-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  AppManagerTreeNodeAttributesControlSetTreeNodeAttributeMutation,
  AppManagerTreeNodeAttributesControlSetTreeNodeAttributeMutationVariables,
} from './__generated__/AppManagerTreeNodeAttributesControlSetTreeNodeAttributeMutation';
import { AppManagerTreeNodeAttributesControlAttributeConfigurationFragment } from './__generated__/AppManagerTreeNodeAttributesControlAttributeConfigurationFragment';
import setTreeNodeAttributeMutation from './set-tree-node-attribute.gql';

@Component({
  data(this: TreeNodeAttribute) {
    return { model: this.value };
  },
})
export default class TreeNodeAttribute extends Mixins(Busyable) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @ObjectProp(true)
  private readonly attributeConfiguration!: AppManagerTreeNodeAttributesControlAttributeConfigurationFragment;

  @StringProp('')
  private readonly value!: string;

  private model!: string;

  private get modelState(): string {
    return this.busy ? 'GRAY' : this.value === this.model ? 'GREEN' : 'RED';
  }

  private resetModel(): void {
    this.model = this.value;
  }

  @Watch('model')
  @Busy()
  private async save(): Promise<void> {
    // checking busy works because its only set to true after the current microtask
    if (this.busy || this.model === this.value) {
      return;
    }

    const value = this.model;

    await this.$apollo.mutate<
      AppManagerTreeNodeAttributesControlSetTreeNodeAttributeMutation,
      AppManagerTreeNodeAttributesControlSetTreeNodeAttributeMutationVariables
    >({
      mutation: setTreeNodeAttributeMutation,
      variables: {
        input: { treeNodeId: this.treeNodeId, attributeDefinitionName: this.attributeConfiguration.name, value },
      },
    });

    if (value !== this.value) {
      return; // something gone wrong
    }

    setTimeout(() => this.save(), 0);
  }
}
