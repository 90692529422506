























































import { defineComponent } from '@vue/composition-api';
import { DomainUiTreeNodeFileTableTreeNodeFileFragment } from '@/features/domain-ui/tree-node-file-table/__generated__/DomainUiTreeNodeFileTableTreeNodeFileFragment';
import { KEYCLOAK } from '@/features/core/container';
import download from 'downloadjs';
import deleteTreeNodeFileMutation from '@/features/app-manager/components/tree-node-files-control/delete-tree-node-file.gql';
import { AppManagerTreeNodeFilesControlUploadTreeNodeFileMutationVariables } from '@/features/app-manager/components/tree-node-files-control/__generated__/AppManagerTreeNodeFilesControlUploadTreeNodeFileMutation';
import uploadTreeNodeFileMutation from '@/features/app-manager/components/tree-node-files-control/upload-tree-node-file.gql';
import query from '@/features/app-manager/components/tree-node-files-control/view.gql';

const MAX_FILE_UPLOAD_SIZE_GIGA_BYTES = 10 * 1024 * 1024;

interface DataConsumptionPollPanelProps {
  gatewayId: string;
}
export default defineComponent<DataConsumptionPollPanelProps, Record<string, unknown>, any, any>({
  components: {},
  props: {
    treeNodeId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      treeNode: null,
      totalPages: 0,
      currentPage: 1,
    };
  },
  computed: {
    count(): number {
      return this.treeNode?.files.count ?? 0;
    },
  },
  async created() {
    await this.loadTreeNode();
  },
  methods: {
    async loadTreeNode() {
      const { data } = await this.$apollo.query({
        query: query,
        fetchPolicy: 'network-only',
        variables: { treeNodeId: this.treeNodeId, skip: 0, take: 10 },
      });
      if (data?.treeNodes?.first) {
        this.treeNode = data.treeNodes.first;
      }
    },
    async uploadTreeNodeFile({
      file,
      label,
    }: Partial<AppManagerTreeNodeFilesControlUploadTreeNodeFileMutationVariables['input']>): Promise<void> {
      if (!file) {
        return;
      }
      if (file.size > MAX_FILE_UPLOAD_SIZE_GIGA_BYTES) {
        throw new Error(
          `Die Datei ist größer als die maximal erlaubten ${MAX_FILE_UPLOAD_SIZE_GIGA_BYTES / 1024 / 1024} MB`,
        );
      }
      await this.$apollo.mutate({
        mutation: uploadTreeNodeFileMutation,
        variables: {
          input: {
            treeNodeId: this.treeNodeId,
            file,
            label,
          },
        },
      });
      this.$apollo.queries.treeNodes.refetch();
    },
    async deleteTreeNodeFile(treeNodeFile: DomainUiTreeNodeFileTableTreeNodeFileFragment): Promise<void> {
      if (
        !window.confirm(`Möchten Sie das Dokument ${treeNodeFile.label ?? treeNodeFile.filename} wirklich löschen?`)
      ) {
        return;
      }
      try {
        const { data } = await this.$apollo.mutate({
          mutation: deleteTreeNodeFileMutation,
          variables: {
            input: {
              id: treeNodeFile.id,
            },
          },
        });
        if (!data) {
          this.ADD_TOAST_MESSAGES({
            messages: [{ text: `Could not remove file.` }],
          });
          return;
        }
      } catch (e) {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: `Error when removing file: ${e}` }],
        });
      }
      await this.loadTreeNode();
    },
    async downloadTreeNodeFile(treeNodeFile: DomainUiTreeNodeFileTableTreeNodeFileFragment): Promise<void> {
      if (this.container(KEYCLOAK).token) {
        const response = await fetch(`${window.location.origin}/api/file/${treeNodeFile.id}`, {
          headers: {
            Authorization: `Bearer ${this.container(KEYCLOAK).token}`,
          },
        });
        const blob = await response.blob();
        download(blob, treeNodeFile.filename);
      } else {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: `Not authorized to download file` }],
        });
      }
    },
  },
});
