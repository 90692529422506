




















































import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import TreeNodeAttribute from '../tree-node-attribute/TreeNodeAttribute.vue';
import {
  AppManagerTreeNodeAttributesControlMassEditModeDescendantsQuery,
  AppManagerTreeNodeAttributesControlMassEditModeDescendantsQueryVariables,
} from './__generated__/AppManagerTreeNodeAttributesControlMassEditModeDescendantsQuery';
import {
  AppManagerTreeNodeAttributesControlMassEditModeQuery,
  AppManagerTreeNodeAttributesControlMassEditModeQueryVariables,
} from './__generated__/AppManagerTreeNodeAttributesControlMassEditModeQuery';
import {
  AppManagerTreeNodeAttributesControlMassEditModeSearchDescendantsQuery,
  AppManagerTreeNodeAttributesControlMassEditModeSearchDescendantsQueryVariables,
} from './__generated__/AppManagerTreeNodeAttributesControlMassEditModeSearchDescendantsQuery';
import { AppManagerTreeNodeAttributesControlMassEditModeTreeNodeFragment } from './__generated__/AppManagerTreeNodeAttributesControlMassEditModeTreeNodeFragment';
import descendantsQuery from './descendants.gql';
import searchDescendantsQuery from './search-descendants.gql';
import treeNodeQuery from './view.gql';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { isString } from '@/util/lang';

type AttributeConfiguration =
  AppManagerTreeNodeAttributesControlMassEditModeQuery['treeNodes']['first']['attributes'][number]['configuration'];
type TreeNode = AppManagerTreeNodeAttributesControlMassEditModeTreeNodeFragment;

interface EditableTreeNode {
  treeNode: TreeNode;
  attribute: TreeNode['attributes'][number];
}

@Component({
  apollo: {
    treeNodes: {
      query: treeNodeQuery,
      fetchPolicy: 'cache-and-network',
      variables(this: MassEditMode): AppManagerTreeNodeAttributesControlMassEditModeQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
    descendants: {
      query: descendantsQuery,
      fetchPolicy: 'cache-and-network',
      skip(this: MassEditMode): boolean {
        return this.attributeConfiguration === null || this.searchQuery !== '';
      },
      variables(this: MassEditMode): AppManagerTreeNodeAttributesControlMassEditModeDescendantsQueryVariables {
        return {
          id: this.treeNodeId,
          types: this.attributeConfiguration?.treeNodeTypes,
          attributeDefinitionName: this.attributeConfiguration?.name ?? '',
        };
      },
    },
    searchDescendants: {
      query: searchDescendantsQuery,
      fetchPolicy: 'cache-and-network',
      skip(this: MassEditMode): boolean {
        return this.attributeConfiguration === null || this.searchQuery === '';
      },
      variables(this: MassEditMode): AppManagerTreeNodeAttributesControlMassEditModeSearchDescendantsQueryVariables {
        return {
          id: this.treeNodeId,
          types: this.attributeConfiguration?.treeNodeTypes,
          attributeDefinitionName: this.attributeConfiguration?.name ?? '',
          searchQuery: this.searchQuery,
        };
      },
      result(this: MassEditMode): void {
        this.searchQuery = this.nextSearchQuery;
      },
    },
  },
  components: { TreeNodeAttribute },
  data(this: MassEditMode) {
    return {
      treeNodes: undefined,
      searchQuery: isString(this.$route.query.query) ? this.$route.query.query : '',
    };
  },
})
export default class MassEditMode extends Mixins(DeviceRoleMapMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeAttributesControlMassEditModeQuery['treeNodes'];
  private readonly descendants?: AppManagerTreeNodeAttributesControlMassEditModeDescendantsQuery['descendants'];
  private readonly searchDescendants?: AppManagerTreeNodeAttributesControlMassEditModeSearchDescendantsQuery['searchDescendants'];

  private searchQuery!: string;

  private readonly columns: Column[] = [{ name: 'path' }, { name: 'name' }, { name: 'value' }];

  private get attributeConfiguration(): AttributeConfiguration | null {
    return this.attributeConfigurations.find(({ name }) => name === this.$route.query.attribute) ?? null;
  }

  private set attributeConfiguration(value: AttributeConfiguration | null) {
    this.$router.replace({ query: { ...this.$route.query, attribute: value === null ? undefined : value.name } });
  }

  private get attributeConfigurations(): AttributeConfiguration[] {
    return this.treeNodes?.first.attributes.map(({ configuration }) => configuration) ?? [];
  }

  private get editableTreeNodes(): EditableTreeNode[] {
    const { attributeConfiguration } = this;
    if (attributeConfiguration === null) {
      return [];
    }

    const treeNodes =
      this.searchQuery === '' ? this.descendants?.items : this.searchDescendants?.items.map(({ treeNode }) => treeNode);
    if (treeNodes === undefined) {
      return [];
    }

    return treeNodes
      .map((treeNode) => ({ treeNode, attribute: treeNode.attributes[0] }))
      .filter((item) => item.attribute?.configuration.name === attributeConfiguration.name);
  }

  private get nextSearchQuery(): string {
    return isString(this.$route.query.query) ? this.$route.query.query : '';
  }

  private set nextSearchQuery(value: string) {
    this.$router.replace({ query: { ...this.$route.query, query: value === '' ? undefined : value } });

    if (!this.$apollo.queries.searchDescendants.loading) {
      this.searchQuery = value;
    }
  }

  private onSpotClick(spot: TreeNode): void {
    this.$router.push({
      name: 'AppManager/TreeNode/Spots/Spot',
      params: { treeNodeId: spot.path.items.slice(-1)[0].id, spotId: spot.id },
    });
  }

  private onGatewayClick(gateway: TreeNode): void {
    this.$router.push({
      name: 'AppManager/TreeNode/Gateways/Details',
      params: { treeNodeId: gateway.path.items.slice(-1)[0].id, gatewayId: gateway.id },
    });
  }

  private onTreeNodeClick(treeNode: TreeNode): void {
    this.$router.push({
      name: 'AppManager/TreeNode',
      params: { treeNodeId: treeNode.id },
    });
  }

  private onPathClick(treeNode: TreeNodePathTreeNode | undefined): void {
    if (!treeNode) {
      return;
    }
    this.$router.push({
      name: 'AppManager/TreeNode',
      params: { treeNodeId: treeNode.id },
    });
  }
}
