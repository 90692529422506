import scrollToElement, { ScrollBehavior } from '@/util/scroll-to-element';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ScrollToRefMixin extends Vue {
  public scrollToRef(ref: string, offset = 60, behavior = ScrollBehavior.CONTAIN): void {
    let target = this.$refs[ref];
    if (target === undefined) {
      return;
    }
    if (Array.isArray(target)) {
      target = target[0];
    }
    if ('$el' in target) {
      target = target.$el;
    }

    scrollToElement(target, offset, behavior);
  }
}
