



































































import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import GroupsMixin from '@/features/core/components/mixins/groups';
import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { RootGetter } from '@/features/core/store';
import ScrollToRefMixin from '@/features/ui/mixins/scroll-to-ref';
import { GatewayHealth, SpotHealth } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { ScrollBehavior } from '@/util/scroll-to-element';
import { Component, Mixins } from 'vue-property-decorator';
import query from './content-directory.gql';
import propertiesQuery from './content-directory-properties.gql';
import alertsQuery from './content-directory-alerts.gql';
import PropertiesView from './properties/PropertiesView.vue';
import {
  AppManagerTreeNodeViewContentDirectoryQuery,
  AppManagerTreeNodeViewContentDirectoryQueryVariables,
} from './__generated__/AppManagerTreeNodeViewContentDirectoryQuery';
import {
  AppManagerContentDirectoryPropertiesQuery,
  AppManagerContentDirectoryPropertiesQueryVariables,
  AppManagerContentDirectoryPropertiesQuery_treeNodes_first_Directory,
  AppManagerContentDirectoryPropertiesQuery_treeNodes_first_Directory_properties,
} from './__generated__/AppManagerContentDirectoryPropertiesQuery';
import { merge } from 'lodash';
import { ApolloQueryResult } from 'apollo-client';
import {
  AppManagerContentDirectoryAlertsQuery,
  AppManagerContentDirectoryAlertsQuery_treeNodes_first_Directory,
  AppManagerContentDirectoryAlertsQuery_treeNodes_first_Directory_properties,
} from './__generated__/AppManagerContentDirectoryAlertsQuery';
import LoadingIndicator from '@/features/core/components/loading-indicator/LoadingIndicator.vue';

const TABS: readonly string[] = [
  'AppManager/TreeNode',
  'AppManager/TreeNode/Apartments',
  'AppManager/TreeNode/Spots',
  'AppManager/TreeNode/Alerts',
  'AppManager/TreeNode/ActivityLogEntries',
  'AppManager/TreeNode/Gateways',
  'AppManager/TreeNode/Gateways/GatewayConfigurationPreset',
  'AppManager/TreeNode/Attributes',
];

@Component({
  components: { LoadingIndicator },
  apollo: {
    treeNodes: {
      query,
      manual: true,
      result(this: ContentDirectory, { data }: ApolloQueryResult<AppManagerTreeNodeViewContentDirectoryQuery>): void {
        this.treeNodes = data.treeNodes;
      },
      variables(this: ContentDirectory): AppManagerTreeNodeViewContentDirectoryQueryVariables {
        return { treeNodeId: this.treeNodeId, userId: this.userId };
      },
    },
    properties: {
      query: propertiesQuery,
      manual: true,
      result(this: ContentDirectory, { data }: ApolloQueryResult<AppManagerContentDirectoryPropertiesQuery>): void {
        this.properties = merge(
          (data.treeNodes.first as AppManagerContentDirectoryPropertiesQuery_treeNodes_first_Directory).properties,
          this.properties ??
            ({} as AppManagerContentDirectoryAlertsQuery_treeNodes_first_Directory_properties &
              AppManagerContentDirectoryPropertiesQuery_treeNodes_first_Directory_properties),
        );
      },
      variables(this: ContentDirectory): AppManagerContentDirectoryPropertiesQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
    alerts: {
      query: alertsQuery,
      manual: true,
      result(this: ContentDirectory, { data }: ApolloQueryResult<AppManagerContentDirectoryAlertsQuery>): void {
        this.properties = merge(
          (data.treeNodes.first as AppManagerContentDirectoryAlertsQuery_treeNodes_first_Directory).properties,
          this.properties ??
            ({} as AppManagerContentDirectoryAlertsQuery_treeNodes_first_Directory_properties &
              AppManagerContentDirectoryPropertiesQuery_treeNodes_first_Directory_properties),
        );
      },
      variables(this: ContentDirectory): AppManagerContentDirectoryPropertiesQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined, properties: undefined };
  },
})
export default class ContentDirectory extends Mixins(
  TreeNodeLocationGeneratorMixin,
  ScrollToRefMixin,
  AppRedirectionMixin,
  GroupsMixin,
) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private treeNodes!: AppManagerTreeNodeViewContentDirectoryQuery['treeNodes'];

  private properties!: AppManagerContentDirectoryPropertiesQuery_treeNodes_first_Directory_properties &
    AppManagerContentDirectoryAlertsQuery_treeNodes_first_Directory_properties;

  private readonly defaultView = PropertiesView;

  @RootGetter
  private readonly userId?: string;

  private created(): void {
    if (TABS.includes(this.$route.name ?? '')) {
      return;
    }

    this.$router.replace({ name: 'AppManager/TreeNode' });
  }

  private onSpotSummaryDetailsClick(health?: SpotHealth): void {
    const query = health === undefined ? undefined : { health };
    this.$router.push({ name: 'AppManager/TreeNode/Spots', query });

    this.scrollToRef('content', 20, ScrollBehavior.DOWN);
  }

  private onGatewaySummaryDetailsClick(health?: GatewayHealth): void {
    const query = health === undefined ? undefined : { health };
    this.$router.push({ name: 'AppManager/TreeNode/Gateways', query });

    this.scrollToRef('content', 20, ScrollBehavior.DOWN);
  }

  private matchTabValue(a: string, b: string): boolean {
    return (
      a === b ||
      (b === 'AppManager/TreeNode/Gateways' && a === 'AppManager/TreeNode/Gateways/GatewayConfigurationPreset')
    );
  }
}
