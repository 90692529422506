





























































import GatewayRebootMixin from '@/features/core/components/mixins/gateway-reboot';
import { NotificationsMixin } from '@/features/core/components/mixins/notifications/notifications';
import { RootAction, Action, RootGetter } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppManagerTreeNodeGatewayDetailsViewQuery,
  AppManagerTreeNodeGatewayDetailsViewQueryVariables,
} from './__generated__/AppManagerTreeNodeGatewayDetailsViewQuery';

@Component({
  apollo: {
    gateways: {
      query,
      fetchPolicy: 'cache-and-network',
      pollInterval: 60000,
      variables(this: GatewayDetailsView): AppManagerTreeNodeGatewayDetailsViewQueryVariables {
        return { ids: this.gatewayId };
      },
    },
  },
  data() {
    return { gateways: undefined };
  },
})
export default class GatewayDetailsView extends Mixins(GatewayRebootMixin, NotificationsMixin) {
  @StringProp(true)
  private readonly gatewayId!: string;

  @RootGetter
  private readonly admin!: boolean;

  @RootGetter
  private readonly technician!: boolean;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly gateways!: AppManagerTreeNodeGatewayDetailsViewQuery['gateways'];

  private currentPage = 1;

  protected get totalPages(): number {
    return Math.max(1, Math.ceil((this.notifications?.count ?? 0) / this.take));
  }

  protected get skip(): number {
    return (this.currentPage - 1) * this.take;
  }

  protected get take(): number {
    return 5;
  }

  protected get contextIds(): string[] {
    return [this.gatewayId];
  }

  private async onReloadClick(): Promise<void> {
    this.currentPage = 1;

    await this.refetchNotifications();
  }
}
