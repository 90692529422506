



































































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { DeviceRole } from '@/features/core/model';
import { RootGetter } from '@/features/core/store';
import { SpotRole } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { groupBy } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import fileIcon from '../../../assets/file.svg';
import inventoryIcon from '../../../assets/inventory.svg';
import qrIcon from '../../../assets/qr.svg';
import PropertyGroupNavigation from './PropertyGroupNavigation.vue';
import query from './view.gql';
import { AppManagerTreeNodePropertyViewPropertySubdivisionFragment } from './__generated__/AppManagerTreeNodePropertyViewPropertySubdivisionFragment';
import {
  AppManagerTreeNodePropertyViewQuery,
  AppManagerTreeNodePropertyViewQueryVariables,
} from './__generated__/AppManagerTreeNodePropertyViewQuery';

type Spot = AppManagerTreeNodePropertyViewPropertySubdivisionFragment['spots']['items'][number];
type HeatingSystem = AppManagerTreeNodePropertyViewPropertySubdivisionFragment['heatingSystems']['items'][number];

interface SpotRoleGroup {
  role: DeviceRole;
  spots: Spot[];
  alert: boolean;
}

type SubdivisionHeatingSystem = HeatingSystem & {
  role: DeviceRole;
  alert: boolean;
};

@Component({
  apollo: {
    properties: {
      query,
      pollInterval: 60000,
      variables(): AppManagerTreeNodePropertyViewQueryVariables {
        return { treeNodeId: this.treeNodeId, userId: this.userId };
      },
    },
  },
  components: { PropertyGroupNavigation },
  data() {
    return { properties: undefined };
  },
})
export default class PropertyView extends Mixins(DeviceRoleMapMixin, TreeNodeLocationGeneratorMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootGetter
  private readonly userId?: string;

  private readonly properties?: AppManagerTreeNodePropertyViewQuery['properties'];

  private readonly inventoryIcon = inventoryIcon;
  private readonly qrIcon = qrIcon;
  private readonly fileIcon = fileIcon;

  private get subdivisions(): AppManagerTreeNodePropertyViewPropertySubdivisionFragment[] {
    return this.properties?.first.propertySubdivisions.items ?? [];
  }

  private get sharedSubdivisions(): AppManagerTreeNodePropertyViewPropertySubdivisionFragment[] {
    return this.properties?.first.sharedPropertySubdivisions.items ?? [];
  }

  private groupSpotsByRole(spots: Spot[]): SpotRoleGroup[] {
    return Object.entries(groupBy(spots, ({ role }) => role))
      .map(([role, spots]) => ({
        role: this.deviceRoleMap[role],
        spots,
        alert: spots.some(({ alerts }) => alerts.more),
      }))
      .filter(({ role }) => role !== undefined)
      .sort((a, b) => a.role.label.localeCompare(b.role.label));
  }

  private subdivisionHeatingSystems(heatingSystems: HeatingSystem[]): SubdivisionHeatingSystem[] {
    return heatingSystems.map((heatingSystem) => ({
      ...heatingSystem,
      role: this.deviceRoleMap[SpotRole.VIESSMANN_HEATING_SYSTEM],
      alert: heatingSystem.alerts.more,
    }));
  }
}
