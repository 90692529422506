











import triangleIcon from '@/assets/images/triangle.svg';
import triangleLeftIcon from '@/assets/images/triangle-left.svg';
import { CoreTreeNodeLocationGeneratorFragment } from '@/features/core/router/__generated__/CoreTreeNodeLocationGeneratorFragment';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppManagerTreeNodePropertyViewQuery } from './__generated__/AppManagerTreeNodePropertyViewQuery';

@Component
export default class PropertyGroupNavigation extends Vue {
  @ObjectProp(true)
  private readonly property!: AppManagerTreeNodePropertyViewQuery['properties']['first'];

  private readonly triangleIcon = triangleIcon;
  private readonly triangleLeftIcon = triangleLeftIcon;

  private get groupProperties(): CoreTreeNodeLocationGeneratorFragment[] {
    return this.property.parent !== null && 'properties' in this.property.parent
      ? this.property.parent.properties.items
      : [];
  }

  private get sharedByIndex(): number {
    return this.groupProperties.findIndex(({ id }) => id === this.property.id);
  }

  private get prevLocation(): CoreTreeNodeLocationGeneratorFragment | undefined {
    return this.getSharedBy(this.sharedByIndex - 1);
  }

  private get nextLocation(): CoreTreeNodeLocationGeneratorFragment | undefined {
    return this.getSharedBy(this.sharedByIndex + 1);
  }

  private getSharedBy(index: number): CoreTreeNodeLocationGeneratorFragment | undefined {
    const { length } = this.groupProperties;
    if (length < 2) {
      return undefined;
    }
    if (length < 3) {
      return index < 0 ? undefined : this.groupProperties[index];
    }

    return this.groupProperties[(index + length) % length];
  }
}
