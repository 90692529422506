























import { PaginationMixin } from '@/features/core/components/mixins/pagination';
import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppManagerTreeNodeActivityLogEntryRatingsWidgetQuery,
  AppManagerTreeNodeActivityLogEntryRatingsWidgetQueryVariables,
} from './__generated__/AppManagerTreeNodeActivityLogEntryRatingsWidgetQuery';

type Rating =
  AppManagerTreeNodeActivityLogEntryRatingsWidgetQuery['treeNodes']['first']['activityLogEntries']['first']['ratings']['items'][number];

@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: RatingsWidget): AppManagerTreeNodeActivityLogEntryRatingsWidgetQueryVariables {
        return {
          activityLogEntryId: this.activityLogEntryId,
          treeNodeId: this.treeNodeId,
          skip: this.skip,
          take: this.take,
        };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class RatingsWidget extends Mixins(PaginationMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;
  @StringProp(true)
  private readonly activityLogEntryId!: string;

  private readonly treeNodes?: AppManagerTreeNodeActivityLogEntryRatingsWidgetQuery['treeNodes'];
  private readonly columns: Column[] = [
    { name: 'score', label: 'Bewertung' },
    { name: 'comment', label: 'Kommentar' },
  ];

  private get ratings(): Rating[] {
    return this.treeNodes?.first.activityLogEntries.first.ratings.items ?? [];
  }

  private get loading(): boolean {
    return this.$apollo.queries.treeNodes.loading;
  }
  protected get count(): number {
    return this.treeNodes?.first.activityLogEntries.first.ratings.count ?? 0;
  }

  private get averageScore(): number | null {
    return this.treeNodes?.first.activityLogEntries.first.ratings.averageScore ?? null;
  }
}
