

























import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { DeviceRole } from '@/features/core/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  AppManagerTreeNodeSpotDetailsViewQuery,
  AppManagerTreeNodeSpotDetailsViewQueryVariables,
} from './__generated__/AppManagerTreeNodeSpotDetailsViewQuery';
import { AppManagerTreeNodeSpotDetailsViewSpotsFragment } from './__generated__/AppManagerTreeNodeSpotDetailsViewSpotsFragment';
import query from './view.gql';
import { RootGetter } from '@/features/core/store';

@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables(): AppManagerTreeNodeSpotDetailsViewQueryVariables {
        return { treeNodeId: this.treeNodeId, userId: this.userId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class SpotDetailsView extends Mixins(TreeNodeLocationGeneratorMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @StringProp()
  private readonly spotId?: string;

  @RootGetter
  private readonly userId?: string;

  private readonly treeNodes?: AppManagerTreeNodeSpotDetailsViewQuery['treeNodes'];

  private get spots(): AppManagerTreeNodeSpotDetailsViewSpotsFragment['spots']['items'] {
    return this.treeNodes && 'spots' in this.treeNodes.first ? this.treeNodes.first.spots.items : [];
  }

  @Watch('treeNodes')
  private checkSpot(): void {
    if (this.spotId === undefined) {
      return;
    }

    if (this.spots.some(({ id }) => id === this.spotId)) {
      return;
    }

    this.$router.replace({ name: 'AppManager/TreeNode', params: { treeNodeId: this.treeNodeId } });
  }

  private onSpotClick(spotId: string, deviceConfig: DeviceRole | undefined): void {
    if (!deviceConfig) {
      return;
    }
    if (deviceConfig.externalDetailsLink) {
      window.open(deviceConfig.externalDetailsLink);
      return;
    }

    this.$router.push({ name: 'AppManager/TreeNode/Spots/Spot', params: { treeNodeId: this.treeNodeId, spotId } });
  }

  private onSpotRemoved(): void {
    this.$router.replace({ name: 'AppManager/TreeNode' });

    this.$apollo.queries.treeNodes.refetch();
  }
}
