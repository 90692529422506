















import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import MassEditMode from './mass-edit-mode/MassEditMode.vue';
import SingleEditMode from './single-edit-mode/SingleEditMode.vue';
import query from './view.gql';
import {
  AppManagerTreeNodeAttributesControlQuery,
  AppManagerTreeNodeAttributesControlQueryVariables,
} from './__generated__/AppManagerTreeNodeAttributesControlQuery';

type Mode = typeof SingleEditMode | typeof MassEditMode;

@Component({
  apollo: {
    treeNodes: {
      query,
      pollInterval: 60000,
      variables(this: TreeNodeAttributesControl): AppManagerTreeNodeAttributesControlQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class TreeNodeAttributesControl extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly singleEditMode = SingleEditMode;
  private readonly massEditMode = MassEditMode;

  private readonly treeNodes?: AppManagerTreeNodeAttributesControlQuery['treeNodes'];

  private get massEditModeAvailable(): boolean {
    return this.treeNodes?.first.children.count !== 0;
  }

  private get mode(): Mode {
    return this.$route.query.mode === 'mass' ? MassEditMode : SingleEditMode;
  }

  private set mode(value: Mode) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mode, ...query } = this.$route.query;
    if (value === MassEditMode) {
      query.mode = 'mass';
    }

    this.$router.replace({ query });
  }
}
