




import { Option, BindingConfig } from '@/components/binding-wizard/model';
import BindingWizardModal from '@/components/binding-wizard/BindingWizardModal.vue';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import ManufacturerMapMixin from '@/features/core/components/mixins/manufacturer-map';
import { ObjectProp } from '@/util/prop-decorators';
import { pick } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppManagerBindingWizardAddSpotMutation,
  AppManagerBindingWizardAddSpotMutationVariables,
} from './__generated__/AppManagerBindingWizardAddSpotMutation';
import {
  AppManagerBindingWizardMountDeviceMutation,
  AppManagerBindingWizardMountDeviceMutationVariables,
} from './__generated__/AppManagerBindingWizardMountDeviceMutation';
import { AppManagerBindingWizardTreeNodeFragment } from './__generated__/AppManagerBindingWizardTreeNodeFragment';
import addSpotMutation from './add-spot.gql';
import mountDeviceMutation from './mount-device.gql';

@Component({ components: { BindingWizardModal } })
export default class BindingWizardModalControl extends Mixins(DeviceRoleMapMixin, ManufacturerMapMixin) {
  @ObjectProp(true)
  private treeNode!: AppManagerBindingWizardTreeNodeFragment;

  private get manufacturerOptions(): Option[] {
    return Object.values(this.manufacturerMap)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ name, label, image }) => ({
        value: name,
        label,
        icon: image,
      }));
  }

  private get roleOptions(): Option[] {
    return Object.values(this.deviceRoleMap)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ name, label, icons: { md } }) => ({
        value: name,
        label,
        icon: md,
      }));
  }

  private async addAction(config: BindingConfig): Promise<void> {
    const spotId = await this.addSpot(config);
    await this.mountDevice(spotId, config);

    this.$emit('added', spotId);
  }

  private async addSpot(config: BindingConfig): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppManagerBindingWizardAddSpotMutation,
      AppManagerBindingWizardAddSpotMutationVariables
    >({
      mutation: addSpotMutation,
      variables: {
        input: {
          parentId: this.treeNode.id,
          ...pick(config, 'name', 'roomName', 'notes', 'role'),
        },
      },
    });

    if (!data) {
      throw new Error('Gerät konnte nicht hinzugefügt werden!');
    }

    return data.addSpot.spot.id;
  }

  private async mountDevice(spotId: string, config: BindingConfig): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppManagerBindingWizardMountDeviceMutation,
      AppManagerBindingWizardMountDeviceMutationVariables
    >({
      mutation: mountDeviceMutation,
      variables: { input: { spotId, ...pick(config, 'manufacturer', 'serial', 'encryptionKey') } },
    });

    if (!data) {
      throw new Error('Gerät konnte nicht hinzugefügt werden!');
    }

    return data.mountDevice.deviceMount.id;
  }
}
