
























import { defineComponent } from '@vue/composition-api';
import query from '@/features/app-manager/components/tree-node-attributes-control/single-edit-mode/view.gql';
import { TreeNodeType } from '@/types/iot-portal';
import {
  AppManagerTreeNodeAttributesControlSingleEditModeQuery,
  AppManagerTreeNodeAttributesControlSingleEditModeQuery_treeNodes_first_attributes,
} from '@/features/app-manager/components/tree-node-attributes-control/single-edit-mode/__generated__/AppManagerTreeNodeAttributesControlSingleEditModeQuery';
import TreeNodeAttribute from '@/features/app-manager/components/tree-node-attributes-control/tree-node-attribute/TreeNodeAttribute.vue';

interface TreeNodeAttributesProps {
  treeNodeId: string;
}
type TreeNodes = AppManagerTreeNodeAttributesControlSingleEditModeQuery['treeNodes'];
type Attribute = TreeNodes['first']['attributes'][number];
export default defineComponent<TreeNodeAttributesProps, Record<string, unknown>, any, any>({
  components: { TreeNodeAttribute },
  props: {
    treeNodeId: {
      type: String,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      treeNode: null,
    };
  },
  computed: {
    attributes(): Attribute[] {
      if (!this.treeNode) {
        return [];
      }
      return this.treeNode.attributes.filter(
        ({ configuration }: AppManagerTreeNodeAttributesControlSingleEditModeQuery_treeNodes_first_attributes) =>
          configuration.treeNodeTypes.includes(TreeNodeType[this.treeNode.__typename as TreeNodeType]),
      );
    },
  },
  async created() {
    await this.loadAttributes();
  },
  methods: {
    async loadAttributes() {
      const { data } = await this.$apollo.query({
        query: query,
        fetchPolicy: 'network-only',
        variables: {
          treeNodeId: this.treeNodeId,
        },
      });
      if (data?.treeNodes?.first) {
        this.treeNode = data.treeNodes.first;
      }
    },
  },
});
