<!-- JsonItem.vue -->
<template>
  <div>
    <div v-if="isObject">
      <h3>{{ name }}</h3>
      <ul>
        <li v-for="(value, key) in propertyValue" :key="key">
          <JsonItem :name="key" :property-value="value !== null ? value : 'Null'" />
        </li>
      </ul>
    </div>
    <div v-else-if="isArray">
      <h3>{{ name }}</h3>
      <ol>
        <li v-for="(item, index) in propertyValue" :key="index">
          <JsonItem :name="index" :property-value="item !== null ? item : 'Null'" />
        </li>
      </ol>
    </div>
    <div v-else>
      <strong>{{ name }}:</strong> {{ displayValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'JsonItem',
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    propertyValue: {
      type: [Object, Array, String, Number, Boolean],
      required: true,
    },
  },
  computed: {
    isObject() {
      return (
        typeof this.propertyValue === 'object' && this.propertyValue !== null && !Array.isArray(this.propertyValue)
      );
    },
    isArray() {
      return Array.isArray(this.propertyValue);
    },
    displayValue() {
      return this.propertyValue === null ? 'null' : this.propertyValue.toString();
    },
  },
};
</script>

<style scoped>
ul,
ol {
  list-style-type: none;
  padding-left: 20px;
}

strong {
  display: inline-block;
  width: 150px;
}

h3 {
  margin-top: 10px;
  font-size: 1.2em;
  color: #333;
}
</style>
