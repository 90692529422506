













import { PropertyFloorType, PropertySubdivisionVisualizationHint } from '@/types/iot-portal';
import { ArrayProp } from '@/util/prop-decorators';
import { groupBy, partition } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { AppManagerBuildingPropertySubdivisionFragment } from './__generated__/AppManagerBuildingPropertySubdivisionFragment';
import BuildingFloor from './BuildingFloor.vue';
import { Floor } from './model';

@Component({ components: { BuildingFloor } })
export default class Building extends Vue {
  @ArrayProp(() => [])
  private readonly subdivisions!: AppManagerBuildingPropertySubdivisionFragment[];

  @ArrayProp(() => [])
  private readonly sharedSubdivisions!: AppManagerBuildingPropertySubdivisionFragment[];

  private get floorsByType(): Partial<Record<PropertyFloorType, Floor[]>> {
    return Object.fromEntries(
      Object.entries(groupBy([...this.subdivisions, ...this.sharedSubdivisions], ({ floor }) => floor.type)).map(
        ([type, subdivisions]) => {
          const levels = Object.values(groupBy(subdivisions, ({ floor }) => floor.level))
            .map((subdivisions) => {
              const { floor } = subdivisions[0];
              const [left, right] = partition(
                subdivisions
                  .sort((a, b) => a.order - b.order || a.name.localeCompare(b.name))
                  .map((subdivision) => ({ subdivision, shared: this.sharedSubdivisions.includes(subdivision) })),
                floor.type === PropertyFloorType.ROOF
                  ? () => true
                  : ({ subdivision }) =>
                      subdivision.visualizationHint !== PropertySubdivisionVisualizationHint.STAIRWELL,
              );

              return { ...floor, left, right };
            })
            .sort((a, b) => b.level - a.level);

          return [type, levels] as [PropertyFloorType, Floor[]];
        },
      ),
    );
  }
}
