






import MiniSensor from '@/components/mini-sensor/MiniSensor.vue';
import { StringProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { MiniSensor }, inheritAttrs: false })
export default class BuildingSpotRole extends Vue {
  @StringProp(true)
  private readonly icon!: string;

  @BooleanProp()
  private readonly alert!: boolean;
}
