











import { Component, Vue } from 'vue-property-decorator';
import { ArrayProp, DateProp, StringProp } from '@/util/prop-decorators';
import { AppManagerTreeNodeViewContentPropertyQuery_treeNodes_first_Property_attributes } from '../__generated__/AppManagerTreeNodeViewContentPropertyQuery';
import {
  getFilteredAttributesList,
  getAttributeValue,
} from '@/features/domain-ui/eed-consumption/water-consumption/util';
import { WaterConsumptionAttributesType } from '@/features/domain-ui/eed-consumption/model';

@Component
export default class PropertyWaterConsumption extends Vue {
  @StringProp()
  private readonly treeNodeId!: string;

  @DateProp()
  private readonly period!: Date;

  @ArrayProp()
  private readonly attributes!: AppManagerTreeNodeViewContentPropertyQuery_treeNodes_first_Property_attributes[];

  private get heatValueAttributeIsPresent(): boolean {
    const attributeHeatValueList = getFilteredAttributesList(
      this.attributes,
      WaterConsumptionAttributesType.CALORIC_VALUE,
    );
    if (attributeHeatValueList !== undefined && getAttributeValue(attributeHeatValueList) !== null) {
      return true;
    }
    return false;
  }
}
