
















import { TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import TreeNodeAttribute from '../tree-node-attribute/TreeNodeAttribute.vue';
import {
  AppManagerTreeNodeAttributesControlSingleEditModeQuery,
  AppManagerTreeNodeAttributesControlSingleEditModeQueryVariables,
} from './__generated__/AppManagerTreeNodeAttributesControlSingleEditModeQuery';
import query from './view.gql';

type TreeNodes = AppManagerTreeNodeAttributesControlSingleEditModeQuery['treeNodes'];
type Attribute = TreeNodes['first']['attributes'][number];

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: SingleEditMode): AppManagerTreeNodeAttributesControlSingleEditModeQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  components: { TreeNodeAttribute },
  data() {
    return { treeNodes: undefined };
  },
})
export default class SingleEditMode extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: TreeNodes;

  private get attributes(): Attribute[] {
    const { treeNodes } = this;
    if (treeNodes === undefined) {
      return [];
    }

    return treeNodes.first.attributes.filter(({ configuration }) =>
      configuration.treeNodeTypes.includes(TreeNodeType[treeNodes.first.__typename]),
    );
  }
}
