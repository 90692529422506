































import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppManagerActivityLogEntryFormSubmissionsViewQuery,
  AppManagerActivityLogEntryFormSubmissionsViewQueryVariables,
} from './__generated__/AppManagerActivityLogEntryFormSubmissionsViewQuery';

type FormSubmission =
  AppManagerActivityLogEntryFormSubmissionsViewQuery['treeNodes']['first']['activityLogEntries']['first']['formSubmissions']['items'][number];
type Activity = AppManagerActivityLogEntryFormSubmissionsViewQuery['treeNodes']['first']['activityLogEntries']['first'];
@Component({
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: FormSubmissionsView): AppManagerActivityLogEntryFormSubmissionsViewQueryVariables {
        const { treeNodeId, activityLogEntryId } = this;
        return { treeNodeId, activityLogEntryId };
      },
    },
  },
  data() {
    return {
      treeNodes: undefined,
    };
  },
})
export default class FormSubmissionsView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @StringProp(true)
  private readonly activityLogEntryId!: string;

  private readonly treeNodes?: AppManagerActivityLogEntryFormSubmissionsViewQuery['treeNodes'];

  private get activity(): Activity | undefined {
    return this.treeNodes?.first.activityLogEntries.first;
  }
  private get formSubmissions(): FormSubmission[] {
    return this.treeNodes?.first.activityLogEntries.first.formSubmissions.items ?? [];
  }

  private get columns(): Column[] {
    return [{ name: 'name' }, { name: 'value', align: 'right' }];
  }

  private async exportTemplateToPdf(event: Event): Promise<void> {
    const buttonElement = event.target as HTMLElement;
    const rootExportElement = buttonElement.closest('.panel-container')?.querySelectorAll('.flex')?.[1];
    if (!rootExportElement) {
      return;
    }
    const html2pdf = await import('html2pdf.js');
    html2pdf
      .default()
      .set({
        margin: 0,
        filename: `aktivitaeten-qr-codes.pdf`,
        jsPDF: { format: 'a4' },
      })
      .from(rootExportElement)
      .save();
  }

  private routeToForm(formSubmission: FormSubmission): void {
    if (formSubmission.form === null) {
      return;
    }
    this.$router.push({
      name: 'AppCustomer/Form',
      params: { customerId: formSubmission.form.customer.id, formId: formSubmission.form.id },
    });
  }
}
