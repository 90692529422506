

















































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import { BookmarkActivityTreeNodeInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import download from 'downloadjs';
import QRCode from 'qrcode';
import { Component, Vue, Watch } from 'vue-property-decorator';
import bookmarkActivityTreeNodeMutation from './bookmark-activity-tree-node.gql';
import query from './view.gql';
import {
  AppManagerActivityTreeNodeBookmarkModalBookmarkActivityTreeNodeMutation,
  AppManagerActivityTreeNodeBookmarkModalBookmarkActivityTreeNodeMutationVariables,
} from './__generated__/AppManagerActivityTreeNodeBookmarkModalBookmarkActivityTreeNodeMutation';
import {
  AppManagerActivityTreeNodeBookmarkModalQuery,
  AppManagerActivityTreeNodeBookmarkModalQueryVariables,
} from './__generated__/AppManagerActivityTreeNodeBookmarkModalQuery';
import { generateBookmarkTokenUrl } from '@/features/domain-ui/activity-tree-node-bookmark/util';

type Activity = AppManagerActivityTreeNodeBookmarkModalQuery['treeNodes']['first']['activities'][number];
type Bookmark =
  AppManagerActivityTreeNodeBookmarkModalQuery['treeNodes']['first']['activityBookmarks']['items'][number];

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: ActivityTreeNodeBookmarkModal): AppManagerActivityTreeNodeBookmarkModalQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined, dataUrl: undefined };
  },
})
export default class ActivityTreeNodeBookmarkModal extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly treeNodes?: AppManagerActivityTreeNodeBookmarkModalQuery['treeNodes'];

  private dataUrl?: string;

  private get bookmark(): Bookmark | undefined {
    return this.treeNodes?.first.activityBookmarks.items[0];
  }

  private get url(): string | undefined {
    return this.bookmark === undefined ? undefined : generateBookmarkTokenUrl(this.bookmark.token);
  }

  private get activities(): Activity[] {
    return this.treeNodes?.first.activities ?? [];
  }

  private get activityOptions(): Option[] {
    return this.activities.map(({ id, name }) => ({ value: id, label: name }));
  }

  @Watch('url')
  private async updateQrCode(url: string | undefined): Promise<void> {
    if (url === undefined) {
      this.dataUrl = undefined;
      return;
    }

    const dataUrl = await QRCode.toDataURL(url);
    if (url === this.url) {
      this.dataUrl = dataUrl;
    }
  }

  private async downloadQrCode(): Promise<void> {
    if (this.dataUrl !== undefined) {
      const { slugify } = await import('transliteration');
      download(this.dataUrl, `qr-aktivitaet-${slugify(this.treeNodes?.first.name ?? this.treeNodeId)}.png`);
    }
  }

  private async copyToClipboard(): Promise<void> {
    if (this.url !== undefined) {
      await navigator.clipboard.writeText(this.url);

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Eintragungslink kopiert', class: 'success' }],
      });
    }
  }

  private async bookmarkActivityTreeNode(input: Omit<BookmarkActivityTreeNodeInput, 'treeNodeId'>): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppManagerActivityTreeNodeBookmarkModalBookmarkActivityTreeNodeMutation,
      AppManagerActivityTreeNodeBookmarkModalBookmarkActivityTreeNodeMutationVariables
    >({
      mutation: bookmarkActivityTreeNodeMutation,
      variables: { input: { ...input, treeNodeId: this.treeNodeId, token: this.bookmark?.token } },
      update: (store, { data }) => {
        if (data === undefined || data === null) {
          return;
        }

        const cachedData = store.readQuery<AppManagerActivityTreeNodeBookmarkModalQuery>({
          query,
          variables: { treeNodeId: this.treeNodeId },
        });
        if (cachedData === null) {
          return;
        }
        cachedData.treeNodes.first.activityBookmarks.items = [data.bookmarkActivityTreeNode.activityTreeNodeBookmark];

        store.writeQuery<AppManagerActivityTreeNodeBookmarkModalQuery>({
          query,
          variables: { treeNodeId: this.treeNodeId },
          data: cachedData,
        });
      },
    });

    if (!data) {
      throw new Error('Der Eintragungslink konnte nicht gespeichert werden!');
    }
  }

  private async removeBookmark(): Promise<void> {
    if (this.bookmark === undefined) {
      return;
    }
    const { data } = await this.$apollo.mutate<
      AppManagerActivityTreeNodeBookmarkModalBookmarkActivityTreeNodeMutation,
      AppManagerActivityTreeNodeBookmarkModalBookmarkActivityTreeNodeMutationVariables
    >({
      mutation: bookmarkActivityTreeNodeMutation,
      variables: { input: { token: this.bookmark.token, activityIds: [], treeNodeId: this.treeNodeId } },
      update: (store, { data }) => {
        if (data === undefined || data === null) {
          return;
        }

        const cachedData = store.readQuery<AppManagerActivityTreeNodeBookmarkModalQuery>({
          query,
          variables: { treeNodeId: this.treeNodeId },
        });
        if (cachedData === null) {
          return;
        }
        cachedData.treeNodes.first.activityBookmarks.items = cachedData.treeNodes.first.activityBookmarks.items.filter(
          (bookmark) => bookmark.token !== data.bookmarkActivityTreeNode.activityTreeNodeBookmark.token,
        );
      },
    });

    if (!data) {
      throw new Error('Der Eintragungslink konnte nicht deaktiviert werden!');
    }
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }
}
