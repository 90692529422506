

















































import { formatMetricName } from '@/features/core/util/metric-formatters';
import { Column } from '@/features/ui/table/model';
import { EditSpotInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import editSpotMutation from './edit-spot.gql';
import query from './spots.gql';
import {
  AppManagerEditSpotPanelControlEditMutation,
  AppManagerEditSpotPanelControlEditMutationVariables,
} from './__generated__/AppManagerEditSpotPanelControlEditMutation';
import {
  AppManagerEditSpotPanelControlQuery,
  AppManagerEditSpotPanelControlQueryVariables,
} from './__generated__/AppManagerEditSpotPanelControlQuery';

interface FormData {
  input: Pick<EditSpotInput, 'name' | 'roomName' | 'notes'>;
  metricConfigurations: Record<string, Omit<EditSpotInput['metricConfigurations'][number], 'name'>>;
}

@Component({
  apollo: {
    spots: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: EditSpotPanelControl): AppManagerEditSpotPanelControlQueryVariables {
        return { spotId: this.spotId };
      },
    },
  },
  data() {
    return { spots: undefined };
  },
})
export default class EditSpotPanelControl extends Vue {
  @StringProp(true)
  private readonly spotId!: string;

  private readonly spots?: AppManagerEditSpotPanelControlQuery['spots'];

  private readonly metricColumns: Column[] = [
    { name: 'name', label: 'Name' },
    { name: 'label', label: 'Beschriftung' },
    { name: 'shortLabel', label: 'Kurzbezeichnung' },
    { name: 'position', label: 'Sortierung' },
  ];

  private get initialData(): Partial<FormData> {
    return {
      input: this.spots?.first,
      metricConfigurations: Object.fromEntries(
        this.spots?.first.metricConfigurations.map((config) => [config.name, config]) ?? [],
      ),
    };
  }

  private get metrics(): { name: string; defaultLabel: string }[] {
    return [
      ...new Set([
        ...(this.spots?.first.metrics.map(({ name }) => name) ?? []),
        ...(this.spots?.first.metricConfigurations.map(({ name }) => name) ?? []),
      ]),
    ]
      .map((name) => ({ name, defaultLabel: formatMetricName(name) }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  private async editSpot({ input, metricConfigurations }: FormData): Promise<void> {
    await this.$apollo.mutate<
      AppManagerEditSpotPanelControlEditMutation,
      AppManagerEditSpotPanelControlEditMutationVariables
    >({
      mutation: editSpotMutation,
      variables: {
        input: {
          ...input,
          metricConfigurations: Object.entries(metricConfigurations).map(([name, config]) => ({ ...config, name })),
          order: this.spots?.first.order ?? 0,
          spotId: this.spotId,
        },
      },
    });
  }
}
