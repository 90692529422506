









import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { ObjectProp, StringProp } from '@/util/prop-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  AppManagerActivityLogEntryAttributesAttributeControlSetActivityLogEntryAttributeMutation,
  AppManagerActivityLogEntryAttributesAttributeControlSetActivityLogEntryAttributeMutationVariables,
} from './__generated__/AppManagerActivityLogEntryAttributesAttributeControlSetActivityLogEntryAttributeMutation';
import { AppManagerActivityLogEntryAttributesAttributeControlAttributeConfigurationFragment } from './__generated__/AppManagerActivityLogEntryAttributesAttributeControlAttributeConfigurationFragment';
import setActivityLogEntryAttributeMutation from './set-activity-log-entry-attribute.gql';

@Component({
  data(this: ActivityLogEntryAttributeControl) {
    return { model: this.value };
  },
})
export default class ActivityLogEntryAttributeControl extends Mixins(Busyable) {
  @StringProp(true)
  private readonly activityLogEntryId!: string;

  @ObjectProp(true)
  private readonly attributeConfiguration!: AppManagerActivityLogEntryAttributesAttributeControlAttributeConfigurationFragment;

  @StringProp('')
  private readonly value!: string;

  private model!: string;

  private get modelState(): string {
    return this.busy ? 'GRAY' : this.value === this.model ? 'GREEN' : 'RED';
  }

  private resetModel(): void {
    this.model = this.value;
  }

  @Watch('model')
  @Busy()
  private async save(): Promise<void> {
    // checking busy works because its only set to true after the current microtask
    if (this.busy || this.model === this.value) {
      return;
    }

    const value = this.model;

    await this.$apollo.mutate<
      AppManagerActivityLogEntryAttributesAttributeControlSetActivityLogEntryAttributeMutation,
      AppManagerActivityLogEntryAttributesAttributeControlSetActivityLogEntryAttributeMutationVariables
    >({
      mutation: setActivityLogEntryAttributeMutation,
      variables: {
        input: {
          activityLogEntryId: this.activityLogEntryId,
          attributeDefinitionName: this.attributeConfiguration.name,
          value,
        },
      },
    });

    if (value !== this.value) {
      return; // something gone wrong
    }

    setTimeout(() => this.save(), 0);
  }
}
