<template lang="pug">
  .app-manager-subdivision-action
    ui-clickable
      slot
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BuildingSubdivisionAction extends Vue {}
</script>
