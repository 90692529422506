








import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppManagerTreeNodePropertiesViewQuery,
  AppManagerTreeNodePropertiesViewQueryVariables,
} from './__generated__/AppManagerTreeNodePropertiesViewQuery';

@Component({
  apollo: {
    properties: {
      query,
      pollInterval: 60000,
      variables(this: PropertiesView): AppManagerTreeNodePropertiesViewQueryVariables {
        return { treeNodeId: this.treeNodeId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { properties: undefined };
  },
})
export default class PropertiesView extends Mixins(TreeNodeLocationGeneratorMixin, PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly properties?: AppManagerTreeNodePropertiesViewQuery['properties'];

  protected get count(): number {
    return this.properties?.count ?? 0;
  }
}
