






















import { defineComponent } from '@vue/composition-api';
import { Column } from '@/features/ui/table/model';
import { Order } from '@/types/iot-portal';
import gatewayReplacementLogsQuery from '@/features/app-manager/views/tree-node/gateway-new-details/gatewayReplacements.gql';

interface GatewayDetailReplacementHistoryProps {
  treeNodeId: string;
}
export default defineComponent<GatewayDetailReplacementHistoryProps, Record<string, unknown>, any, any>({
  components: {},
  props: {
    treeNodeId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      columns: [
        { name: 'path', label: 'Standort', width: 150 },
        { name: 'installDate', label: 'Datum der Installation', width: 150 },
        { name: 'removeDate', label: 'Datum der Beseitigung', width: 150 },
      ] as Column[],
      replacements: [],
      loading: true,
      sortKeys: [
        {
          key: 'installDate',
          direction: Order.ASC,
        },
      ],
      // pagination
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
    };
  },
  computed: {},
  async created() {
    await this.loadReplacements();
  },
  methods: {
    async loadReplacements() {
      try {
        const { data } = await this.$apollo.query({
          query: gatewayReplacementLogsQuery,
          fetchPolicy: 'network-only',
          variables: { id: this.treeNodeId, skip: 0, take: this.pageSize },
        });
        if (data?.gatewaysReplacements?.items) {
          const loadedReplacements = data.gatewaysReplacements.items;
          this.replacements = this.processLogs(loadedReplacements);
        }
        // Get the count
        if (data?.gatewaysReplacements?.count) {
          this.totalPages = Math.ceil(data?.gatewaysReplacements?.count / this.pageSize);
        }
        this.loading = false;
      } catch (error) {
        console.log('error', error);
      }
    },
    processLogs(logs: any) {
      return logs.map((log: any) => {
        return {
          ...log,
          installDate: new Date(log.installDate).toLocaleString(),
          removeDate: log.removeDate ? new Date(log.removeDate).toLocaleString() : '',
        };
      });
    },
  },
});
