






import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class GatewayConfigurationPresetView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  @StringProp()
  private readonly gatewayId?: string;
}
