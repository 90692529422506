
































































































import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import GroupsMixin from '@/features/core/components/mixins/groups';
import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { RootGetter } from '@/features/core/store';
import ScrollToRefMixin from '@/features/ui/mixins/scroll-to-ref';
import { GatewayHealth, SpotHealth } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { ScrollBehavior } from '@/util/scroll-to-element';
import { Component, Mixins } from 'vue-property-decorator';
import query from './content-property.gql';
import PropertyHeatConsumption from './property/PropertyHeatConsumption.vue';
import PropertyView from './property/PropertyView.vue';
import PropertyWaterConsumption from './property/PropertyWaterConsumption.vue';
import {
  AppManagerTreeNodeViewContentPropertyQuery,
  AppManagerTreeNodeViewContentPropertyQueryVariables,
  AppManagerTreeNodeViewContentPropertyQuery_treeNodes_first_Property_attributes,
} from './__generated__/AppManagerTreeNodeViewContentPropertyQuery';
import LoadingIndicator from '@/features/core/components/loading-indicator/LoadingIndicator.vue';

const TABS: readonly string[] = [
  'AppManager/TreeNode',
  'AppManager/TreeNode/Apartments',
  'AppManager/TreeNode/Spots',
  'AppManager/TreeNode/Alerts',
  'AppManager/TreeNode/ActivityLogEntries',
  'AppManager/TreeNode/GroundPlan',
  'AppManager/TreeNode/Inventory',
  'AppManager/TreeNode/Gateways',
  'AppManager/TreeNode/Gateways/GatewayConfigurationPreset',
  'AppManager/TreeNode/Files',
  'AppManager/TreeNode/Attributes',
];

@Component({
  apollo: {
    treeNodes: {
      query,
      pollInterval: 60000,
      variables(this: ContentProperty): AppManagerTreeNodeViewContentPropertyQueryVariables {
        return {
          treeNodeId: this.treeNodeId,
          aggregationRoles: Object.values(this.deviceRoleMap)
            .filter(({ aggregationWidget }) => aggregationWidget?.enabled === true)
            .map(({ name }) => name),
          userId: this.userId,
        };
      },
      // Error handling function
      error(error) {
        this.hasError = true;
        this.errorMessage = error;
      },
    },
  },
  components: {
    LoadingIndicator,
    PropertyHeatConsumption,
    PropertyWaterConsumption,
  },
  data() {
    return {
      treeNodes: undefined,
      hasError: false,
      errorMessage: undefined,
    };
  },
})
export default class ContentProperty extends Mixins(
  DeviceRoleMapMixin,
  ScrollToRefMixin,
  TreeNodeLocationGeneratorMixin,
  AppRedirectionMixin,
  GroupsMixin,
) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeViewContentPropertyQuery['treeNodes'];

  private readonly defaultView = PropertyView;

  @RootGetter
  private readonly userId?: string;

  private get inventoryCount(): number {
    if (this.treeNodes === undefined || !this.treeNodes?.first || !('tree' in this.treeNodes?.first)) {
      return 0;
    }

    return this.treeNodes.first.tree.items.reduce(
      (sum, item) => sum + ('inventory' in item && item.inventory !== null ? item.inventory.items.length : 0),
      0,
    );
  }

  private created(): void {
    if (TABS.includes(this.$route.name ?? '')) {
      return;
    }

    this.$router.replace({ name: 'AppManager/TreeNode' });
  }

  private onSpotSummaryDetailsClick(health?: SpotHealth): void {
    const query = health === undefined ? undefined : { health };
    this.$router.push({ name: 'AppManager/TreeNode/Spots', query });

    this.scrollToRef('content', 20, ScrollBehavior.DOWN);
  }

  private onGatewaySummaryDetailsClick(health?: GatewayHealth): void {
    const query = health === undefined ? undefined : { health };
    this.$router.push({ name: 'AppManager/TreeNode/Gateways', query });

    this.scrollToRef('content', 20, ScrollBehavior.DOWN);
  }

  private matchTabValue(a: string, b: string): boolean {
    return (
      a === b ||
      (b === 'AppManager/TreeNode/Gateways' && a === 'AppManager/TreeNode/Gateways/GatewayConfigurationPreset')
    );
  }

  private get currentMonth(): Date {
    return new Date();
  }

  private get propertyAttributes(): AppManagerTreeNodeViewContentPropertyQuery_treeNodes_first_Property_attributes[] {
    if (this.treeNodes === undefined || !this.treeNodes?.first || !('attributes' in this.treeNodes.first)) {
      return [];
    }
    return this.treeNodes.first.attributes;
  }
}
