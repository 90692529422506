
























import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppManagerGatewayConfigurationPanelGatewayFragment } from './__generated__/AppManagerGatewayConfigurationPanelGatewayFragment';
import { AppManagerGatewayConfigurationPanelGatewayConfigurationFragment } from './__generated__/AppManagerGatewayConfigurationPanelGatewayConfigurationFragment';
import { Column } from '@/features/ui/table/model';

type ConfigurationValue = string | number | boolean | null;
type ConfigurationProperty = {
  name: keyof AppManagerGatewayConfigurationPanelGatewayConfigurationFragment;
  group: string;
  label: string;
};
type ConfigurationEntry = ConfigurationProperty & { value: ConfigurationValue; target: ConfigurationValue };

const PROPERTIES: ConfigurationProperty[] = [
  { name: 'accessPointExternalEnabled', group: 'WLAN', label: 'Aktiviert' },
  { name: 'accessPointExternalSsid', group: 'WLAN', label: 'SSID' },
  { name: 'accessPointExternalPreSharedKey', group: 'WLAN', label: 'Schlüssel' },
  { name: 'lightBrightness', group: 'Licht', label: ' Helligkeit' },
  { name: 'lightDuration', group: 'Licht', label: 'Dauer (in Sekunden)' },
  { name: 'lightEmergencyBrightness', group: 'Notlicht', label: 'Helligkeit' },
  { name: 'lightEmergencyTimeout', group: 'Notlicht', label: 'Max. Unterbrechungsdauer (in Sekunden)' },
  { name: 'lightEmergencyInterval', group: 'Notlicht', label: 'Prüfintervall (in Sekunden)' },
  { name: 'lightPingInterval', group: 'Notlicht', label: 'Ping-Intervall (in Sekunden)' },
];

@Component
export default class GatewayConfigurationPanel extends Vue {
  @ObjectProp(true)
  private readonly gateway!: AppManagerGatewayConfigurationPanelGatewayFragment;

  private readonly columns: Column[] = [
    { name: 'group', label: 'Gruppe' },
    { name: 'label', label: 'Option' },
    { name: 'value', label: 'Aktueller Wert', align: 'right' },
    { name: 'target', label: 'Soll-Wert', align: 'right' },
    { name: 'state', label: '' },
  ];

  private get configurationEntries(): ConfigurationEntry[] {
    const { configuration, gatewayTargetConfiguration } = this.gateway;

    return PROPERTIES.map(({ name, ...rest }) => ({
      name,
      ...rest,
      value: configuration === null ? null : configuration[name],
      target: name in gatewayTargetConfiguration ? gatewayTargetConfiguration[name] : null,
    }));
  }

  private formatValue(value: string | number | boolean | null): string {
    switch (value) {
      case null:
        return '–';
      case true:
        return 'Ja';
      case false:
        return 'Nein';
      default:
        return String(value);
    }
  }
}
