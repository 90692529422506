import { RebootGatewayInput } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import rebootGatewayMutation from './gateway-reboot.gql';
import {
  CoreGatewayRebootMutation,
  CoreGatewayRebootMutationVariables,
} from './__generated__/CoreGatewayRebootMutation';

@Component
export default class GatewayRebootMixin extends Vue {
  protected async rebootGateway(input: RebootGatewayInput): Promise<void> {
    await this.$apollo.mutate<CoreGatewayRebootMutation, CoreGatewayRebootMutationVariables>({
      mutation: rebootGatewayMutation,
      variables: { input },
    });
  }
}
