





















































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { StringProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import moment from 'moment';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import aggregationsQuery from './aggregations.gql';
import activityLogEntriesQuery from './activity-log-entries.gql';
import {
  AppManagerTreeNodeActivityLogEntriesViewAggregationsQuery,
  AppManagerTreeNodeActivityLogEntriesViewAggregationsQueryVariables,
} from './__generated__/AppManagerTreeNodeActivityLogEntriesViewAggregationsQuery';
import {
  AppManagerTreeNodeActivityLogEntriesViewActivityLogEntriesQuery,
  AppManagerTreeNodeActivityLogEntriesViewActivityLogEntriesQueryVariables,
} from './__generated__/AppManagerTreeNodeActivityLogEntriesViewActivityLogEntriesQuery';
import RatingsWidget from './ratings-widget/RatingsWidget.vue';
import {
  AppManagerTreeNodeActivityLogEntriesViewRemoveActivityLogEntriesMutation,
  AppManagerTreeNodeActivityLogEntriesViewRemoveActivityLogEntriesMutationVariables,
} from './__generated__/AppManagerTreeNodeActivityLogEntriesViewRemoveActivityLogEntriesMutation';
import removeActivityLogEntries from './remove-activity-log-entries.gql';
import { PAGINATION_PER_PAGE } from '@/features/ui/pagination/PageSize.global.vue';

type Activity =
  AppManagerTreeNodeActivityLogEntriesViewAggregationsQuery['aggregations']['first']['activityAggregations']['activityAggregations'][number];
type ActivityLogEntry =
  AppManagerTreeNodeActivityLogEntriesViewActivityLogEntriesQuery['activityLogEntries']['first']['activityLogEntries']['items'][number];

@Component({
  components: { RatingsWidget },
  apollo: {
    activityLogEntries: {
      query: activityLogEntriesQuery,
      fetchPolicy: 'cache-and-network',
      pollInterval: 60000,
      variables(
        this: ActivityLogEntriesView,
      ): AppManagerTreeNodeActivityLogEntriesViewActivityLogEntriesQueryVariables {
        return {
          treeNodeId: this.treeNodeId,
          take: this.take,
          skip: this.skip,
          activityIds: this.activity ? [this.activity.id] : null,
          since: this.since === null ? null : moment(this.since).startOf('day').toDate(),
          before: this.before === null ? null : moment(this.before).startOf('day').add(1, 'day').toDate(),
        };
      },
      skip(this: ActivityLogEntriesView): boolean {
        return isString(this.$route.query.activity) && this.aggregations === undefined;
      },
    },
    aggregations: {
      query: aggregationsQuery,
      fetchPolicy: 'cache-and-network',
      variables(this: ActivityLogEntriesView): AppManagerTreeNodeActivityLogEntriesViewAggregationsQueryVariables {
        return {
          treeNodeId: this.treeNodeId,
          since: this.since === null ? null : moment(this.since).startOf('day').toDate(),
          before: this.before === null ? null : moment(this.before).startOf('day').add(1, 'day').toDate(),
          activityIds: this.activity ? [this.activity.id] : null,
        };
      },
    },
  },
  data() {
    return {
      activityLogEntries: undefined,
      aggregations: undefined,
      selectedActivityLogEntries: [],
      perPage: PAGINATION_PER_PAGE.TWENTY_FIVE,
    };
  },
})
export default class ActivityLogEntriesView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private activityLogEntries?: AppManagerTreeNodeActivityLogEntriesViewActivityLogEntriesQuery['activityLogEntries'];
  private aggregations?: AppManagerTreeNodeActivityLogEntriesViewAggregationsQuery['aggregations'];
  private selectedActivityLogEntries!: ActivityLogEntry[];

  private expandedRows: number[] = [];
  private perPage!: number;

  private get activity(): Activity | null {
    return isString(this.$route.query.activity)
      ? this.activities?.find(({ name }) => this.$route.query.activity === name) ?? null
      : null;
  }

  private set activity(value: Activity | null) {
    this.$router.replace({ query: { ...this.$route.query, activity: value?.name ?? undefined } });
  }

  private get since(): Date | null {
    return isString(this.$route.query.since) ? new Date(this.$route.query.since) : null;
  }

  private set since(value: Date | null) {
    this.$router.replace({ query: { ...this.$route.query, since: value?.toISOString() ?? undefined } });
  }

  private get before(): Date | null {
    return isString(this.$route.query.before) ? new Date(this.$route.query.before) : null;
  }

  private set before(value: Date | null) {
    this.$router.replace({ query: { ...this.$route.query, before: value?.toISOString() ?? undefined } });
  }

  protected get count(): number {
    return this.activityLogEntries?.first.activityLogEntries.count ?? 0;
  }

  private get activities(): Activity[] {
    return this.aggregations?.first.activityAggregations.activityAggregations ?? [];
  }

  private get logEntries(): ActivityLogEntry[] {
    return this.activityLogEntries?.first.activityLogEntries.items ?? [];
  }

  private get activityLogEntriesLoading(): boolean {
    return this.$apollo.queries.activityLogEntries.loading;
  }

  private get activityAggregationsLoading(): boolean {
    return this.$apollo.queries.aggregations.loading;
  }

  private get disabledDates(): { from: Date; to: Date | undefined } {
    if (this.aggregations === undefined) {
      return {
        from: moment().toDate(),
        to: undefined,
      };
    }

    const { min, max } = this.aggregations.first.checkInDateAggregations.checkInDateAggregation;

    return {
      from: moment(max).add(1, 'day').toDate(),
      to: moment(min).subtract(1, 'day').toDate(),
    };
  }

  private get totalDuration(): string | undefined {
    if (this.activity === null || this.activity.totalDuration === null) {
      return undefined;
    }
    return moment.duration(this.activity.totalDuration).humanize();
  }

  private toggleRowExpansion(rowIndex: number): void {
    if (this.isRowExpanded(rowIndex)) {
      this.expandedRows = this.expandedRows.filter((currentRowIndex) => currentRowIndex !== rowIndex);
    } else {
      this.expandedRows.push(rowIndex);
    }
  }

  private isRowExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }

  @Watch('currentPage')
  private resetExpandedRows(): void {
    this.expandedRows = [];
  }

  protected get take(): number {
    return this.perPage;
  }

  private async removeSelectedActivityLogEntries(): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppManagerTreeNodeActivityLogEntriesViewRemoveActivityLogEntriesMutation,
      AppManagerTreeNodeActivityLogEntriesViewRemoveActivityLogEntriesMutationVariables
    >({
      mutation: removeActivityLogEntries,
      variables: {
        input: { ids: this.selectedActivityLogEntries.map(({ id }) => id) },
      },
    });

    if (!data) {
      throw new Error(`Couldn't delete the log entries`);
    }

    this.selectedActivityLogEntries = [];
    this.$apollo.queries.activityLogEntries.refetch();
  }
}
