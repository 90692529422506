




import { defineComponent } from '@vue/composition-api';
import ContextNotifications from '@/features/core/components/notifications/ContextNotifications.vue';

interface GatewayDetailsNotificationsProps {
  gatewayId: string;
}
export default defineComponent<GatewayDetailsNotificationsProps, Record<string, unknown>, any, any>({
  components: { ContextNotifications },
  props: {
    gatewayId: {
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
    empty(): boolean {
      return this.notifications.length === 0;
    },
  },
  methods: {},
});
