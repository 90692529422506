
























import { defineComponent, inject, ref } from '@vue/composition-api';
import { mapActions, mapGetters } from 'vuex';
import { RebootGatewayInput } from '@/types/iot-portal';
import rebootGatewayMutation from '@/features/core/components/mixins/gateway-reboot.gql';
import { GatewayDetails_gateways_first } from '@/features/app-manager/views/tree-node/gateway-new-details/__generated__/GatewayDetails';
import JSONObject from '@/features/core/components/json-object/JsonObject.vue';

interface GatewayDetailsDetailsProps {
  gateway: GatewayDetails_gateways_first;
}
export default defineComponent<GatewayDetailsDetailsProps, Record<string, unknown>, any, any>({
  components: { JSONObject },
  props: {
    gateway: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      componentGateway: null,
    };
  },
  computed: {
    ...mapGetters(['admin', 'technician']),
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async saveGateway(input: RebootGatewayInput) {
      const { data } = await this.$apollo.mutate({
        query: rebootGatewayMutation,
        fetchPolicy: 'network-only',
        variables: { input },
      });
    },
  },
});
