















































import ContainerMixin from '@/features/core/components/mixins/container';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { KEYCLOAK } from '@/features/core/container';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { DomainUiTreeNodeFileTableTreeNodeFileFragment } from '@/features/domain-ui/tree-node-file-table/__generated__/DomainUiTreeNodeFileTableTreeNodeFileFragment';
import { StringProp } from '@/util/prop-decorators';
import download from 'downloadjs';
import { Component, Mixins } from 'vue-property-decorator';
import deleteTreeNodeFileMutation from './delete-tree-node-file.gql';
import uploadTreeNodeFileMutation from './upload-tree-node-file.gql';
import query from './view.gql';
import {
  AppManagerTreeNodeFilesControlDeleteTreeNodeFileMutation,
  AppManagerTreeNodeFilesControlDeleteTreeNodeFileMutationVariables,
} from './__generated__/AppManagerTreeNodeFilesControlDeleteTreeNodeFileMutation';
import {
  AppManagerTreeNodeFilesControlQuery,
  AppManagerTreeNodeFilesControlQueryVariables,
} from './__generated__/AppManagerTreeNodeFilesControlQuery';
import {
  AppManagerTreeNodeFilesControlUploadTreeNodeFileMutation,
  AppManagerTreeNodeFilesControlUploadTreeNodeFileMutationVariables,
} from './__generated__/AppManagerTreeNodeFilesControlUploadTreeNodeFileMutation';

const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024;

@Component({
  apollo: {
    treeNodes: {
      query,
      pollInterval: 60000,
      variables(this: TreeNodeFilesControl): AppManagerTreeNodeFilesControlQueryVariables {
        return { treeNodeId: this.treeNodeId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class TreeNodeFilesControl extends Mixins(PaginationQueryStringMixin, ContainerMixin) {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppManagerTreeNodeFilesControlQuery['treeNodes'];

  protected get count(): number {
    return this.treeNodes?.first.files.count ?? 0;
  }

  private async uploadTreeNodeFile({
    file,
    label,
  }: Partial<AppManagerTreeNodeFilesControlUploadTreeNodeFileMutationVariables['input']>): Promise<void> {
    if (!file) {
      return;
    }
    if (file.size > MAX_FILE_UPLOAD_SIZE) {
      throw new Error(`Die Datei ist größer als die maximal erlaubten ${MAX_FILE_UPLOAD_SIZE / 1024 / 1024} MB`);
    }
    await this.$apollo.mutate<
      AppManagerTreeNodeFilesControlUploadTreeNodeFileMutation,
      AppManagerTreeNodeFilesControlUploadTreeNodeFileMutationVariables
    >({
      mutation: uploadTreeNodeFileMutation,
      variables: {
        input: {
          treeNodeId: this.treeNodeId,
          file,
          label,
        },
      },
    });
    this.$apollo.queries.treeNodes.refetch();
  }

  private async deleteTreeNodeFile(treeNodeFile: DomainUiTreeNodeFileTableTreeNodeFileFragment): Promise<void> {
    if (!window.confirm(`Möchten Sie das Dokument ${treeNodeFile.label ?? treeNodeFile.filename} wirklich löschen?`)) {
      return;
    }
    try {
      const { data } = await this.$apollo.mutate<
        AppManagerTreeNodeFilesControlDeleteTreeNodeFileMutation,
        AppManagerTreeNodeFilesControlDeleteTreeNodeFileMutationVariables
      >({
        mutation: deleteTreeNodeFileMutation,
        variables: {
          input: {
            id: treeNodeFile.id,
          },
        },
      });
      if (!data) {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: `Could not remove file.` }],
        });
        return;
      }
    } catch (e) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: `Error when removing file: ${e}` }],
      });
    }
    this.$apollo.queries.treeNodes.refetch();
  }

  private async downloadTreeNodeFile(treeNodeFile: DomainUiTreeNodeFileTableTreeNodeFileFragment): Promise<void> {
    const response = await fetch(`${window.location.origin}/api/file/${treeNodeFile.id}`, {
      headers: {
        Authorization: `Bearer ${this.container(KEYCLOAK).token || ''}`,
      },
    });
    const blob = await response.blob();
    download(blob, treeNodeFile.filename);
  }
}
