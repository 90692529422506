










import { Component, Vue } from 'vue-property-decorator';
import { AppManagerAppApartmentsViewQuery } from './__generated__/AppManagerAppApartmentsViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    propertySubdivisions: {
      query,
      pollInterval: 60000,
    },
  },
  data() {
    return { propertySubdivisions: undefined };
  },
})
export default class ApartmentsView extends Vue {
  private propertySubdivisions?: AppManagerAppApartmentsViewQuery['propertySubdivisions'];
}
